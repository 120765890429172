import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";
import VLFSelectNew from "@/components/Input/SelectNew";

import {PlaylistAPI} from "@/api/playlist";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";

import {
	SUCCESSFUL_OPERATION,
	ERROR_OPERATION,
	playlistOptions,
} from "@/utils/constants";
import {checkRequiredFields, getTextField} from "@/utils/functions";
import {useCategories, usePlayers} from "@/utils/hooks";

import "./index.scss";

const HandleVideoPage = (props) => {
	const {edit, handleNotification} = props;

	const type = new URLSearchParams(useLocation().search).get("type");

	const navigate = useNavigate();
	const {id} = useParams();

	const {categories} = useCategories({
		setLoading: false,
		handleNotification,
	});
	const {players} = usePlayers({
		setLoading: false,
		handleNotification,
	});

	const [loading, setLoading] = useState(false);
	const [fields, setFields] = useState({
		name: null,
		playlistType: type,
		categories: null,
	});

	const getPlaylist = async () => {
		setLoading(true);
		try {
			const response = await PlaylistAPI.getById(id);

			const currentPlayer = players?.filter(
				(p) => p.id === response.playerId,
			)?.[0];
			response.categories = response.categories.map((c) => {
				return {
					value: c.playersCategoryId,
					label: c.playersCategory,
				};
			});
			response.player = response.playerId
				? {
						value: response.playerId,
						label:
							currentPlayer.firstName +
							" " +
							currentPlayer.lastName +
							" (" +
							currentPlayer.category +
							")",
					}
				: null;

			setFields(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (edit && players?.length) {
			getPlaylist();
		}
	}, [edit, players]);

	const getRequiredFields = () => {
		return !fields?.categories?.length && !fields?.player
			? ["name", "playlistType", "categories", "player"]
			: fields?.categories?.length
				? ["name", "playlistType", "categories"]
				: ["name", "playlistType", "player"];
	};

	const savePlaylist = async () => {
		try {
			const errors = checkRequiredFields(getRequiredFields(), fields);
			setErrors(errors);

			if (!errors.length) {
				setLoading(true);

				let body = Object.assign({}, fields);

				body = {
					...fields,
					playlistType: fields.playlistType.value,
					categories: fields?.categories?.map((c) => {
						return {
							playersCategoryId: c.value,
							playersCategory: c.label,
						};
					}),
					playerId: fields?.player?.value,
				};
				const response = edit
					? await PlaylistAPI.update(id, body)
					: await PlaylistAPI.create(body);
				setTimeout(() => {
					navigate(
						`/video/${response?.id || id}` + (type ? "?type=" + type : ""),
					);
					setLoading(false);
					handleNotification(SUCCESSFUL_OPERATION, "success");
				}, 1000);
			}
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		savePlaylist();
	};

	const goBack = () => {
		if (edit) {
			navigate("/video/" + id + (type ? "?type=" + type : ""));
		} else {
			navigate("/video" + (type ? "?type=" + type : ""));
		}
	};

	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: goBack,
			color: "info",
		},
		{
			icon: <SaveIcon />,
			title: "Salva",
			onClick: handleSubmit,
			disabled: checkRequiredFields(getRequiredFields(), fields).length
				? true
				: false,
		},
	];

	const breadcrumbsElements = [
		{label: "Video", path: "/video" + (type ? "?type=" + type : "")},
	];
	if (edit) {
		breadcrumbsElements.push(
			{label: "/"},
			{
				label: fields ? fields.name || "" : "",
				path: "/video/" + id + (type ? "?type=" + type : ""),
			},
		);
	}

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="sheet-container">
				<div className="row no-margin">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Informazioni principali"
							content={
								<div className="row">
									<div className="col-sm-12 col-md-6">
										{getTextField({
											fieldKey: "name",
											label: "Nome",
											required: true,
											values: fields,
											setValues: setFields,
										})}
									</div>
									<div className="col-sm-12 col-md-6">
										<VLFSelectNew
											options={playlistOptions}
											value={fields?.playlistType}
											onChange={(newValue) => {
												setFields({...fields, playlistType: newValue});
											}}
											label="Tipologia"
											editable={false}
											required
										/>
									</div>
									<div className="col-sm-12 col-md-6">
										<VLFSelectNew
											options={categories.map((c) => {
												return {
													value: c.id,
													label: c.name,
												};
											})}
											value={fields.categories}
											onChange={(newValue) => {
												setFields({
													...fields,
													categories: newValue,
													player: null,
												});
											}}
											label="Categoria"
											isMulti
											required={!fields?.player}
										/>
									</div>
									<div className="col-sm-12 col-md-6">
										<VLFSelectNew
											options={players.map((p) => {
												return {
													value: p.id,
													label:
														p.firstName +
														" " +
														p.lastName +
														" (" +
														p.category +
														")",
												};
											})}
											value={fields.player}
											onChange={(newValue) => {
												setFields({
													...fields,
													player: newValue,
													categories: null,
												});
											}}
											label="Portiere"
											required={!fields?.categories?.length}
										/>
									</div>
								</div>
							}
							openable={false}
						/>
					</div>
				</div>
			</div>
		</Page>
	);
};

export default HandleVideoPage;
