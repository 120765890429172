import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import moment from "moment";

import Loading from "@/components/Loading";
import Page from "@/components/Page";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";
import ViewField from "@/components/Input/ViewField";
import NotesSection from "@/components/NotesSection";
import AttachmentsContainer from "@/components/AttachmentsContainer";
import VLFModal from "@/components/Modal";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import {MatchObservedAPI} from "@/api/match_observed";
import {AttachmentAPI} from "@/api/attachment";

import {
	dateFormat,
	ERROR_OPERATION,
	SUCCESSFUL_OPERATION,
} from "@/utils/constants";

import "./index.scss";

function ScoutMatchPage(props) {
	const {handleNotification} = props;
	const {id} = useParams();
	const navigate = useNavigate();

	const basePath = "/scout-matches";

	const [loading, setLoading] = useState(false);
	const [openHandleConfirm, setOpenHandleConfirm] = useState(false);
	const [matchData, setMatchData] = useState({});
	const [matchNotes, setMatchNotes] = useState([]);
	const [attachments, setAttachments] = useState([]);

	const [entityToDelete, setEntityToDelete] = useState({});

	const getMatch = async () => {
		setLoading(true);
		try {
			const response = await MatchObservedAPI.getById(id);
			setMatchData(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const getMatchNotes = async () => {
		try {
			const response = await MatchObservedAPI.getNotes(id);
			setMatchNotes(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};

	const getMatchAttachment = async () => {
		try {
			const response = await AttachmentAPI.list("match", id);
			setAttachments(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};

	useEffect(() => {
		getMatch();
		getMatchAttachment();
		getMatchNotes();
	}, []);

	const deleteMatch = async () => {
		setLoading(true);
		try {
			await MatchObservedAPI.delete(matchData?.id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			navigate(basePath);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		}
	};
	const deleteAttachment = async () => {
		setLoading(true);
		try {
			await AttachmentAPI.delete(entityToDelete?.id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			setOpenHandleConfirm(false);
			getMatchAttachment();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const getAttachment = async (id) => {
		setLoading(true);
		try {
			const currentFile = attachments.filter((a) => a.id === id)?.[0];
			const response = await AttachmentAPI.getById(id, {responseType: "blob"});

			// Ottieni l'estensione del file
			const extension = currentFile.filePath
				.split("\\")
				.pop()
				.split(".")
				.pop()
				.toLowerCase();
			let mimeType = "application/octet-stream"; // Default per file sconosciuti

			// eslint-disable-next-line default-case
			switch (extension) {
				case "pdf":
					mimeType = "application/pdf";
					break;
				case "png":
					mimeType = "image/png";
					break;
				case "jpg":
				case "jpeg":
					mimeType = "image/jpeg";
					break;
				case "doc":
					mimeType = "doc";
					break;
			}

			const blob = new Blob([response], {type: mimeType});

			const url = window.URL.createObjectURL(blob);

			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", currentFile.filePath.split("\\").pop());

			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			handleNotification(SUCCESSFUL_OPERATION, "success");
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const handleNote = async (data, id) => {
		setLoading(true);
		try {
			const body = {
				date: data?.date || moment().format("YYYY-MM-DD"),
				matchId: matchData?.id,
				...data,
			};
			delete body.player;

			id
				? await MatchObservedAPI.updateNote(matchData?.id, id, body)
				: await MatchObservedAPI.createNote(body);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			getMatchNotes();
			handleClose();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};
	const deleteNote = async (id) => {
		setLoading(true);
		try {
			await MatchObservedAPI.deleteNote(matchData?.id, id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			getMatchNotes();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const matchConfiguration = [
		{
			value: matchData?.playersCategory,
			label: "Categoria",
			size: "col-6 col-lg-4",
		},
		{
			value: matchData?.startDate
				? moment(matchData.startDate).format(dateFormat + " HH:mm")
				: null,
			label: "Data",
			size: "col-6 col-lg-4",
		},
		{
			value: matchData?.homeTeam,
			label: "Squadra casa",
			size: "col-6 col-lg-4",
		},
		{
			value: matchData?.awayTeam,
			label: "Squadra ospite",
			size: "col-6 col-lg-4",
		},
		{
			value: matchData?.homeTeamGoals + " - " + matchData?.awayTeamGoals,
			label: "Risultato",
			size: "col-6 col-lg-4",
		},
		// {
		// 	value: matchData?.matchType,
		// 	label: "Tipologia",
		// 	size: "col-6 col-lg-4",
		// },
	];

	matchConfiguration.push(
		{
			value: matchData?.fieldName,
			label: "Campo",
			size: "col-6 col-lg-4",
		},
		{
			value: matchData?.description,
			label: "Descrizione",
			size: "col-6 col-lg-4",
		},
	);

	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: () => navigate(basePath),
			color: "info",
		},
		{
			icon: <EditIcon />,
			title: "Modifica",
			onClick: () => navigate(basePath + "/" + matchData?.id + "/edit"),
		},
		{
			icon: <DeleteIcon />,
			title: "Elimina",
			onClick: () => {
				setEntityToDelete({type: "match", id});
				setOpenHandleConfirm(true);
			},
			color: "error",
		},
	];

	const breadcrumbsElements = [
		{label: "Partite", path: basePath},
		{label: "/"},
	];

	return (
		<Page>
			<Loading visible={loading} />
			<div className="player-container">
				<Breadcrumbs
					elements={breadcrumbsElements}
					current={
						matchData?.startDate
							? matchData.homeTeam + " - " + matchData.awayTeam
							: ""
					}
					actions={<MainActions actions={mainActions} />}
				/>
				<div className="sheet-container">
					<div className="row no-margin col-container">
						<div className="col-sm-12 padding-small">
							<VLFCard
								title="Informazioni principali"
								content={
									<div className="row">
										<div className="col-sm-12 col-md-2">
											<div className="image-container generic">
												<img
													src={require("@/assets/images/coach/match.png")}
													alt=""
												/>
											</div>
										</div>
										<div className="col-sm-12 col-md-10">
											<ViewField configuration={matchConfiguration} />
										</div>
									</div>
								}
							/>
						</div>
						<div className="col-sm-12 no-padding mt-2">
							<NotesSection
								type="match"
								external
								notes={matchNotes}
								handleConfirm={handleNote}
								handleDelete={deleteNote}
							/>
						</div>
						<div className="col-sm-12 padding-small mt-2">
							<VLFCard
								title="Allegati"
								content={
									<AttachmentsContainer
										entityId={id}
										entityType="match"
										attachments={attachments}
										handleAdd={() => getMatchAttachment()}
										handleDownload={getAttachment}
										handleDelete={(id) => {
											setEntityToDelete({type: "attachment", id});
											setOpenHandleConfirm(true);
										}}
										handleNotification={handleNotification}
									/>
								}
							/>
						</div>
					</div>
				</div>
			</div>
			<VLFModal
				open={openHandleConfirm}
				title="Eliminazione"
				content={
					<div className="mt-2">
						Sei sicuro di voler eliminare questo elemento?
					</div>
				}
				confirm={() => {
					switch (entityToDelete?.type) {
						case "match":
							return deleteMatch();
						case "attachment":
							return deleteAttachment();
						default:
							return;
					}
				}}
				close={() => setOpenHandleConfirm(false)}
				confirmLabel="Conferma"
			/>
		</Page>
	);
}

export default ScoutMatchPage;
