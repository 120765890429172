import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import moment from "moment";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import CardsList from "@/components/CardsList";

import {PlayerAPI} from "@/api/player";

import AddIcon from "@mui/icons-material/Add";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";

import {ERROR_OPERATION} from "@/utils/constants";
import {useCategories} from "@/utils/hooks";

import "./index.scss";

// const player = {
// 	rating: 90,
// 	name: "Cristiano Ronaldo",
// 	position: "ST",
// 	pace: 91,
// 	shooting: 93,
// 	passing: 82,
// 	dribbling: 89,
// 	defense: 35,
// 	physical: 78,
// 	clubBadge: require("@/assets/images/logo_player.png"),
// 	countryFlag: require("@/assets/images/logo_player.png"),
// 	image: require("@/assets/images/ronaldo.jpeg"),
// };

// const FifaCard = ({player}) => {
// 	return (
// 		<div className="fifa-card">
// 			<div className="fifa-card-header">
// 				<img src={player.clubBadge} alt="Club Badge" className="club-badge" />
// 				<span className="player-rating">{player.rating}</span>
// 				<img
// 					src={player.countryFlag}
// 					alt="Country Flag"
// 					className="country-flag"
// 				/>
// 			</div>
// 			<img src={player.image} alt="Player" className="player-image" />
// 			<div className="fifa-card-footer">
// 				<span className="player-name">{player.name}</span>
// 				<span className="player-position">{player.position}</span>
// 				<div className="player-stats">
// 					<div className="stat">PAC: {player.pace}</div>
// 					<div className="stat">SHO: {player.shooting}</div>
// 					<div className="stat">PAS: {player.passing}</div>
// 					<div className="stat">DRI: {player.dribbling}</div>
// 					<div className="stat">DEF: {player.defense}</div>
// 					<div className="stat">PHY: {player.physical}</div>
// 				</div>
// 			</div>
// 		</div>
// 	);
// };

// <FifaCard player={player} />

const injuredPlayers = [
	"4b40a7cb-c37c-4de6-a974-bcef9bf07512",
	// "62d4ff58-463d-42dc-b551-99b964884ed2",
];

const topPlayers = [
	"d60bfbc7-e65c-4768-a986-5987c29df193",
	"3eb910e8-0f77-47d0-830b-5726673086ef",
	"493e10ac-be28-4077-9181-10a2f68f7484",
];
const middlePlayers = [
	"62d4ff58-463d-42dc-b551-99b964884ed2",
	"ce1b6b33-1e31-4fc5-b6fd-daa01719da36",
	"43466885-f668-4b64-bb1d-5d33e9fb7763",
	"4b40a7cb-c37c-4de6-a974-bcef9bf07512",
	"df4e9d1e-889d-4e98-bb8a-58bd725d9bed",
	"212ede34-34f9-4a71-a1fa-c41f1cc1f365",
];
const bottomPlayers = [
	"dbbb75c2-b9f1-4554-9277-eefcacd79fdc",
	"cbb71812-5e73-4b5d-afe2-42607d0790bf",
	"2fcd511e-235d-4cb1-95f9-2ba5a7a76d99",
	"5233b6b6-0fe4-4a68-9e96-4f49b267e6ff",
	"efeabd57-d957-4ffb-8397-8dcf25f54d5a",
];

function PlayersPage(props) {
	const {handleNotification} = props;
	const navigate = useNavigate();

	const user = useSelector((state) => state?.user);

	const [loading, setLoading] = useState(false);
	const [players, setPlayers] = useState([]);
	const [filtersData, setFiltersData] = useState(
		JSON.parse(sessionStorage.getItem("player_filters")) || {},
	);
	const [playerType, setPlayerType] = useState("Interno");

	const {categories} = useCategories({setLoading: false, handleNotification});

	const filters = [
		{
			key: "search",
			label: "Portiere",
			value: filtersData?.search,
			setValue: (value) => {
				sessionStorage.setItem(
					"player_filters",
					JSON.stringify({search: value}),
				);
				setFiltersData({...filtersData, search: value});
			},
		},
	];

	const getPlayers = async () => {
		if (!filtersData) {
			setLoading(true);
		}
		try {
			const response = await PlayerAPI.list(filtersData);
			setPlayers(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getPlayers();
	}, [filtersData]);

	const groupedPlayers = {};
	categories
		.filter((c) => c.name !== "Prima Squadra")
		.filter((c) => {
			if (user?.userName === "CaputoD") {
				return parseInt(c?.name?.split(" ")?.[1]) <= 13;
			} else {
				return true;
			}
		})
		.sort((a, b) => a.name?.localeCompare(b.name))
		.forEach((c) => {
			const currentCategory = c.name;
			const playersMembers = players?.filter(
				(p) =>
					p.category === currentCategory &&
					(!playerType || playerType === p.status),
			);
			groupedPlayers[currentCategory] = playersMembers;
		});

	const playersListConfiguration = {
		data: groupedPlayers,
		onClick: (player) => navigate("/players/" + player.id),
		imageUrl: () => require("@/assets/images/manager/player.png"),
		icon: (player) =>
			injuredPlayers.indexOf(player.id) !== -1 && <LocalHospitalIcon />,
		status: (player) =>
			topPlayers.indexOf(player.id) !== -1
				? "top"
				: middlePlayers.indexOf(player.id) !== -1
					? "middle"
					: bottomPlayers.indexOf(player.id) !== -1
						? "bottom"
						: "",
		title: (player) => player.firstName + " " + player.lastName,
		subtitle: (player) =>
			player.dateOfBirth ? moment(player.dateOfBirth).format("YYYY") : "---",
		separator: false,
	};

	const mainActions =
		user?.userName === "CaputoD"
			? []
			: [
					{
						icon: <AddIcon />,
						title: "Aggiungi portiere",
						onClick: () => navigate("/players/new"),
					},
				];

	const listElements = [
		{
			label: "Interni",
			value: "Interno",
			onClick: () => setPlayerType("Interno"),
		},
		{
			label: "Ceduti",
			value: "Esterno",
			onClick: () => setPlayerType("Esterno"),
		},
		{
			label: "In prestito",
			value: "Prestito",
			onClick: () => setPlayerType("Prestito"),
		},
	];

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs
				currentListElement={playerType}
				listElements={listElements}
				actions={<MainActions actions={mainActions} />}
			/>
			<CardsList
				breadcrumbs
				grouped
				filtersData={filters}
				type="players"
				listConfiguration={playersListConfiguration}
				defaultSortedField="category"
				defaultSortedOrder="descend"
			/>
		</Page>
	);
}

export default PlayersPage;
