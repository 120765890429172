import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import VLFCard from "@/components/Card";
import MainActions from "@/components/MainActions";
import VLFTextField from "@/components/Input/TextField";

import {CategoryAPI} from "@/api/category";

import ArrowBack from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";

import {checkRequiredFields} from "@/utils/functions";
import {
	SUCCESSFUL_OPERATION,
	ERROR_OPERATION,
	REQUIRED_FIELD,
} from "@/utils/constants";

import "./index.scss";

const HandleCategoryPage = (props) => {
	const {edit, handleNotification} = props;

	const navigate = useNavigate();
	const {id} = useParams();

	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState([]);
	const [fields, setFields] = useState({});

	const getCategory = async () => {
		setLoading(true);
		try {
			const response = await CategoryAPI.getById(id);
			setFields(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const saveCategory = async () => {
		const requiredFields = ["name"];
		const errors = checkRequiredFields(requiredFields, fields);
		setErrors(errors);

		if (!errors.length) {
			let body = Object.assign({}, fields);
			setLoading(true);
			try {
				edit
					? await CategoryAPI.update(id, body)
					: await CategoryAPI.create(body);
				navigate("/settings/categories");
				handleNotification(SUCCESSFUL_OPERATION, "success");
			} catch (error) {
				handleNotification(ERROR_OPERATION, "error");
				setLoading(false);
			}
		}
	};

	useEffect(() => {
		if (edit) {
			getCategory();
		}
	}, [edit]);

	const handleSubmit = (event) => {
		event.preventDefault();
		saveCategory();
	};

	const getTextField = (
		fieldKey,
		label,
		required,
		values = fields,
		setValues = setFields,
	) => {
		return (
			<VLFTextField
				value={values[fieldKey] || ""}
				onChange={(e) => {
					required && setErrors([]);
					setValues({...values, [fieldKey]: e.target.value});
				}}
				label={label}
				placeholder={label}
				required={required}
				size="small"
				helperText={errors.indexOf(fieldKey) !== -1 ? REQUIRED_FIELD : ""}
				error={errors.indexOf(fieldKey) !== -1}
			/>
		);
	};

	const goBack = () => {
		navigate("/settings/categories");
	};

	const mainActions = [
		{icon: <ArrowBack />, title: "Indietro", onClick: goBack, color: "info"},
		{
			icon: <SaveIcon />,
			title: "Salva",
			onClick: handleSubmit,
		},
	];

	const breadcrumbsElements = [
		{label: "Categorie", path: "/settings/categories"},
	];

	return (
		<Page>
			{loading ? (
				<Loading />
			) : (
				<>
					<Breadcrumbs
						elements={breadcrumbsElements}
						actions={<MainActions actions={mainActions} />}
					/>
					<VLFCard
						title="Informazioni principali"
						content={
							<>
								<div className="row no-margin">
									<div className="col-sm-12 col-md-6 padding-small">
										{getTextField("name", "Nome", true)}
									</div>
								</div>
							</>
						}
					/>
				</>
			)}
		</Page>
	);
};

export default HandleCategoryPage;
