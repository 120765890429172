import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import moment from "moment";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";
import VLFTextField from "@/components/Input/TextField";
import VLFDatePicker from "@/components/Input/DatePicker";
import VLFRadio from "@/components/Input/Radio";
import VLFSelectNew from "@/components/Input/SelectNew";

import {PlayerAPI} from "@/api/player";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";

import {
	genderOptions,
	REQUIRED_FIELD,
	sideOptions,
	SUCCESSFUL_OPERATION,
	ERROR_OPERATION,
	statusOptions,
} from "@/utils/constants";
import {checkRequiredFields} from "@/utils/functions";
import {useCategories} from "@/utils/hooks";

import "./index.scss";

const HandlePlayerPage = (props) => {
	const {edit, handleNotification} = props;

	const navigate = useNavigate();
	const {id} = useParams();

	const userId = useSelector((state) => state?.user?.userId);
	const clubId = useSelector((state) => state?.user?.clubId);
	const userName = useSelector((state) => state?.user?.userName);

	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState([]);
	const [fields, setFields] = useState({
		dateOfBirth: null,
		playerCategory: null,
		status: null,
	});

	const {categories} = useCategories({
		setLoading: false,
		handleNotification: undefined,
		formatted: true,
	});

	const getPlayer = async () => {
		setLoading(true);
		try {
			const response = await PlayerAPI.getById(id);
			response.status = {value: response.status, label: response.status};
			setFields(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (edit) {
			getPlayer();
		}
	}, [edit]);

	const getRequiredFields = () => {
		return edit
			? ["firstName", "lastName"]
			: ["firstName", "lastName", "playerCategory"];
	};

	const savePlayer = async () => {
		const errors = checkRequiredFields(getRequiredFields(), fields);
		setErrors(errors);

		if (!errors.length) {
			setLoading(true);

			let body = Object.assign({}, fields);

			body = {
				...body,
				playerCategoryId: fields?.playerCategory?.value,
				dateOfBirth: fields.dateOfBirth
					? moment(fields.dateOfBirth).format("YYYY-MM-DD")
					: undefined,
				fatherHeight: fields?.fatherHeight || 0,
				motherHeight: fields?.motherHeight || 0,
				clubId,
				userId,
				user: {userId, userName},
				status: fields?.status.value,
			};

			try {
				const response = edit
					? await PlayerAPI.update(id, body)
					: await PlayerAPI.create(body);
				setTimeout(() => {
					navigate(`/players/${response?.id || id}`);
					handleNotification(SUCCESSFUL_OPERATION, "success");
					setLoading(false);
				}, 1000);
			} catch (error) {
				handleNotification(ERROR_OPERATION, "error");
				setLoading(false);
			}
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		savePlayer();
	};

	const getTextField = (
		fieldKey,
		label,
		required,
		values = fields,
		setValues = setFields,
		type = "text",
	) => {
		return (
			<VLFTextField
				type={type}
				value={values[fieldKey] || ""}
				onChange={(e) => {
					required && setErrors([]);
					setValues({...values, [fieldKey]: e.target.value});
				}}
				label={label}
				placeholder={label}
				required={required}
				size="small"
				helperText={errors.indexOf(fieldKey) !== -1 ? REQUIRED_FIELD : ""}
				error={errors.indexOf(fieldKey) !== -1}
			/>
		);
	};

	const goBack = () => {
		if (edit) {
			navigate("/players/" + id);
		} else {
			navigate("/players");
		}
	};

	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: goBack,
			color: "info",
		},
		{
			icon: <SaveIcon />,
			title: "Salva",
			onClick: handleSubmit,
			disabled: checkRequiredFields(getRequiredFields(), fields).length
				? true
				: false,
		},
	];

	const breadcrumbsElements = [{label: "Portieri", path: "/players"}];
	if (edit) {
		breadcrumbsElements.push(
			{label: "/"},
			{
				label: fields
					? (fields.firstName || "") + " " + (fields.lastName || "")
					: "",
				path: "/players/" + id,
			},
		);
	}

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="sheet-container">
				<div className="row no-margin">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Informazioni principali"
							content={
								<div className="row no-margin">
									<div className="col-sm-12 col-md-6">
										{getTextField("firstName", "Nome", true)}
									</div>
									<div className="col-sm-12 col-md-6">
										{getTextField("lastName", "Cognome", true)}
									</div>
									<div className="col-sm-12 col-md-6">
										<VLFDatePicker
											label="Data di nascita"
											value={fields.dateOfBirth}
											onChange={(value) => {
												setErrors([]);
												setFields({...fields, dateOfBirth: value});
											}}
											size="small"
										/>
									</div>
									<div className="col-sm-12 col-md-6">
										{getTextField("placeOfBirth", "Luogo di nascita", false)}
									</div>
									<div className="col-sm-12 col-md-6">
										{getTextField("address", "Indirizzo", false)}
									</div>
									<div className="col-sm-12 col-md-6">
										{getTextField("nationality", "Nazionalità", false)}
									</div>
									<div className="col-sm-12 col-md-6">
										{getTextField("email", "Email", false)}
									</div>
									<div className="col-sm-12 col-md-6">
										{getTextField(
											"telephone",
											"Telefono",
											false,
											fields,
											setFields,
											"phone",
										)}
									</div>
									<div className="col-sm-12 col-md-6">
										<VLFSelectNew
											options={statusOptions}
											value={fields.status}
											onChange={(newValue) => {
												setFields({
													...fields,
													status: newValue,
												});
											}}
											label="Stato"
										/>
									</div>
									<div className="col-sm-12 col-md-6">
										<VLFRadio
											name="Genere"
											value={fields.gender || ""}
											onChange={(event) => {
												setFields({...fields, gender: event.target.value});
											}}
											options={genderOptions}
											label="Genere"
											size="small"
										/>
									</div>
									<div className="col-sm-12 col-md-6">
										<VLFRadio
											name="Mano"
											value={fields.favoriteHand || ""}
											onChange={(event) => {
												setFields({
													...fields,
													favoriteHand: event.target.value,
												});
											}}
											options={sideOptions}
											label="Mano preferita"
											size="small"
										/>
									</div>
									<div className="col-sm-12 col-md-6">
										<VLFRadio
											name="Piede"
											value={fields.favoriteFoot || ""}
											onChange={(event) => {
												setFields({
													...fields,
													favoriteFoot: event.target.value,
												});
											}}
											options={sideOptions}
											label="Piede preferito"
											size="small"
										/>
									</div>
									<div className="col-sm-12 col-md-6">
										{getTextField(
											"fatherHeight",
											"Altezza padre",
											false,
											fields,
											setFields,
											"number",
										)}
									</div>
									<div className="col-sm-12 col-md-6">
										{getTextField(
											"motherHeight",
											"Altezza madre",
											false,
											fields,
											setFields,
											"number",
										)}
									</div>
									<div className="col-sm-12 col-md-6">
										<VLFSelectNew
											options={categories}
											sortOrder="descend"
											value={
												edit ? fields.playerCategoryName : fields.playerCategory
											}
											onChange={(newValue) => {
												setErrors([]);
												setFields({
													...fields,
													playerCategory: newValue,
												});
											}}
											label="Categoria"
											required
											helperText={
												errors.indexOf("playerCategory") !== -1
													? REQUIRED_FIELD
													: ""
											}
											error={errors.indexOf("playerCategory") !== -1}
											editable={!edit}
										/>
									</div>
								</div>
							}
						/>
					</div>
				</div>
			</div>
		</Page>
	);
};

export default HandlePlayerPage;
