import * as React from "react";
import {useNavigate} from "react-router-dom";
import moment from "moment";

import CardsContainer from "@/components/CardsContainer";
import CardsList from "@/components/CardsList";
import CardsTable from "@/components/CardsTable";

import {dateFormat, valuationAnalysisOption} from "@/utils/constants";
import {useCategories} from "@/utils/hooks";

import "./index.scss";

export default function ScoutReportsContainer(props) {
	const {
		reports,
		filters,
		fullHeight = true,
		grouped = true,
		breadcrumbs = true,
	} = props;

	const navigate = useNavigate();

	const {categories} = useCategories({
		setLoading: false,
		handleNotification: undefined,
	});

	const getAlertSeverity = (valuation) => {
		switch (valuation) {
			case 0:
				return {severity: "success", message: "Idoneo", value: "Idoneo"};
			case 1:
				return {severity: "error", message: "Non Idoneo", value: "Non Idoneo"};
			case 2:
				return {
					severity: "warning",
					message: "Da seguire",
					value: "Da seguire",
				};
			case -1:
				return {severity: null, message: null, value: null};
			default:
				return {severity: "info", message: "Nessuna valutazione", value: "N/A"};
		}
	};

	const sortReports = (data) => {
		return data.sort((a, b) => {
			const dateA = new Date(a.prospectPlayer?.dateOfBirth);
			const dateB = new Date(b.prospectPlayer?.dateOfBirth);

			if (a.valuation === 1 && b.valuation !== 1) return 1;
			if (a.valuation !== 1 && b.valuation === 1) return -1;

			return dateB - dateA;
		});
	};

	const columns = [
		{
			label: "Data",
			key: "reportDate",
			customRender: (item) => {
				return moment(item.reportDate).format(dateFormat);
			},
			medium: true,
		},
		{
			label: "Segnalatore",
			key: "signalerName",
			align: "start",
			medium: true,
		},
		{
			label: "Portiere",
			key: "firstName",
			customRender: (item) => {
				return (
					item?.prospectPlayer?.firstName + " " + item?.prospectPlayer?.lastName
				);
			},
			align: "start",
		},
		{
			label: "Data di nascita",
			key: "dateOfBirth",
			customRender: (item) => {
				return moment(item?.prospectPlayer?.dateOfBirth).format(dateFormat);
			},
			medium: true,
		},
		{
			label: "Squadra",
			key: "teamName",
			customRender: (item) => {
				return item?.prospectTeam?.name;
			},
			align: "start",
		},
		// {
		// 	label: "Partita",
		// 	key: "matchObserved",
		// 	align: "start",
		// },
		{
			label: "Valutazione",
			key: "valuation",
			customRender: (item) => {
				const alertData = getAlertSeverity(item.valuation) || {
					severity: "",
					message: "",
				};
				return (
					<div className={"severity " + (alertData.severity || "")}>
						{alertData.message || "---"}
					</div>
				);
			},
		},
		{
			label: "Approfondimento",
			key: "deepening",
			customRender: (item) => {
				const deepening = item?.deepening?.split(",");
				return (
					<div className="d-flex flex-column gap-1">
						{deepening?.map((d, k) => {
							const current = valuationAnalysisOption.filter(
								(o) => o.value === d,
							)?.[0];
							return (
								<div
									key={k}
									className={"severity mx-1" + (current ? " deepening" : "")}
								>
									{current?.label || "---"}
								</div>
							);
						})}
					</div>
				);
			},
		},
	];

	const groupedReports = {};
	categories
		.filter((c) => c.name !== "Prima Squadra")
		.sort((a, b) => a.name?.localeCompare(b.name))
		.forEach((c) => {
			const currentCategory = c.name;
			const members = reports?.filter(
				(r) => r?.playerCategory?.name === currentCategory,
			);
			groupedReports[currentCategory] = sortReports(members);
		});

	const listConfiguration = {
		data: grouped ? groupedReports : sortReports(reports),
		onClick: (report) => navigate("/scout/reports/" + report.id),
	};

	return (
		<CardsContainer
			tableComponent={
				<CardsTable
					breadcrumbs={breadcrumbs}
					fullWidth
					fullHeight={fullHeight}
					middleHeight={!fullHeight}
					filtersData={filters || undefined}
					configurationColumns={columns}
					data={sortReports(reports)}
					handleClick={(report) => {
						navigate("/scout/reports/" + report.id);
					}}
				/>
			}
			listComponent={
				<CardsList
					type="report"
					breadcrumbs={breadcrumbs}
					grouped={grouped}
					filtersData={filters || undefined}
					listConfiguration={listConfiguration}
				/>
			}
		/>
	);
}
