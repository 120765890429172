import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import moment from "moment";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";
import VLFDatePicker from "@/components/Input/DatePicker";

import {ProspectAPI} from "@/api/prospect";

import ArrowBack from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";

import {
	checkRequiredFields,
	getTextField,
	getTextAreaField,
} from "@/utils/functions";
import {SUCCESSFUL_OPERATION, ERROR_OPERATION} from "@/utils/constants";

import "./index.scss";

const HandleScoutPlayerPage = (props) => {
	const {edit, handleNotification} = props;

	const navigate = useNavigate();
	const {id} = useParams();

	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState([]);
	const [fields, setFields] = useState({});

	const getPlayer = async () => {
		setLoading(true);
		try {
			const response = await ProspectAPI.playerById(id);
			response.dateOfBirth =
				moment(response.dateOfBirth)?.year() > 1900
					? response.dateOfBirth
					: null;
			setFields(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (edit) {
			getPlayer();
		}
	}, [edit]);

	const getRequiredFields = () => {
		return ["lastName"];
	};

	const savePlayer = async () => {
		const errors = checkRequiredFields(getRequiredFields(), fields);
		setErrors(errors);

		if (!errors.length) {
			let body = Object.assign({}, fields);
			body = {
				...fields,
				firstName: fields.firstName ? fields.firstName : "",
			};

			setLoading(true);

			try {
				edit
					? await ProspectAPI.updatePlayer(id, body)
					: await ProspectAPI.createPlayer(body);
				navigate("/scout-players");
				handleNotification(SUCCESSFUL_OPERATION, "success");
			} catch (error) {
				handleNotification(ERROR_OPERATION, "error");
				setLoading(false);
			}
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		savePlayer();
	};

	const goBack = () => {
		navigate("/scout-players");
	};

	const mainActions = [
		{icon: <ArrowBack />, title: "Indietro", onClick: goBack, color: "info"},
		{
			icon: <SaveIcon />,
			title: "Salva",
			onClick: handleSubmit,
			disabled: checkRequiredFields(getRequiredFields(), fields).length
				? true
				: false,
		},
	];

	const defaultTextField = {
		values: fields,
		setValues: setFields,
		errors,
		setErrors,
	};

	const playerLabel =
		fields?.firstName || fields?.lastName
			? (fields.firstName || "") + " " + (fields.lastName || "")
			: "";

	const breadcrumbsElements = [{label: "Portieri", path: "/scout-players"}];
	if (edit) {
		breadcrumbsElements.push(
			{label: "/"},
			{
				label: playerLabel,
				path: "/scout-players/" + id,
			},
		);
	}

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="sheet-container">
				<VLFCard
					title="Informazioni principali"
					content={
						<div className="row no-margin">
							<div className="col-12 col-md-6 padding-small">
								{getTextField({
									...defaultTextField,
									fieldKey: "firstName",
									label: "Nome",
								})}
							</div>
							<div className="col-12 col-md-6 padding-small">
								{getTextField({
									...defaultTextField,
									fieldKey: "lastName",
									label: "Cognome",
									required: true,
								})}
							</div>
							<div className="col-12 col-md-6 padding-small">
								<VLFDatePicker
									label="Data di nascita"
									value={fields.dateOfBirth}
									onChange={(value) => {
										setFields({...fields, dateOfBirth: value});
									}}
								/>
							</div>
							<div className="col-12 col-md-6 padding-small">
								{getTextField({
									...defaultTextField,
									type: "number",
									fieldKey: "height",
									label: "Altezza",
								})}
							</div>
							<div className="col-12 col-md-6 padding-small">
								{getTextField({
									...defaultTextField,
									type: "number",
									fieldKey: "weight",
									label: "Peso",
								})}
							</div>
							<div className="col-12 col-md-6 padding-small">
								{getTextField({
									...defaultTextField,
									fieldKey: "favoriteHand",
									label: "Mano principale",
								})}
							</div>
							<div className="col-12 col-md-6 padding-small">
								{getTextField({
									...defaultTextField,
									fieldKey: "favoriteFoot",
									label: "Piede principale",
								})}
							</div>
							<div className="col-12 col-md-12 padding-small">
								{getTextAreaField({
									...defaultTextField,
									fieldKey: "notes",
									label: "Note",
									minRows: 3,
									maxRows: 3,
								})}
							</div>
						</div>
					}
				/>
			</div>
		</Page>
	);
};

export default HandleScoutPlayerPage;
