import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import moment from "moment";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";
import VLFTextField from "@/components/Input/TextField";
import VLFDatePicker from "@/components/Input/DatePicker";
import VLFSelectNew from "@/components/Input/SelectNew";

import {StaffAPI} from "@/api/staff";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";

import {
	REQUIRED_FIELD,
	SUCCESSFUL_OPERATION,
	ERROR_OPERATION,
} from "@/utils/constants";
import {checkRequiredFields} from "@/utils/functions";
import {useCategories, useStaffRoles} from "@/utils/hooks";

import "./index.scss";

const HandleStaffPage = (props) => {
	const {edit, handleNotification} = props;

	const navigate = useNavigate();
	const {id} = useParams();

	const userId = useSelector((state) => state?.user?.userId);
	const clubId = useSelector((state) => state?.user?.clubId);
	const userName = useSelector((state) => state?.user?.userName);

	const {staffRoles} = useStaffRoles({
		setLoading: false,
		handleNotification,
	});
	const {categories} = useCategories({
		setLoading: false,
		handleNotification: undefined,
		formatted: true,
	});

	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState([]);
	const [fields, setFields] = useState({
		dateOfBirth: null,
		staffRole: null,
		staffCategories: null,
	});

	const getStaff = async () => {
		setLoading(true);
		try {
			const response = await StaffAPI.getById(id);
			response.staffRole = {
				value: response.staffRole.id,
				label: response.staffRole.name,
			};
			response.staffCategories = response.staffCategories.map((c) => {
				return {
					value: c.playersCategoryId,
					label: c.playersCategory,
				};
			});
			setFields(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (edit) {
			getStaff();
		}
	}, [edit]);

	const getRequiredFields = () => {
		return ["firstName", "lastName", "staffRole"];
	};

	const saveStaff = async () => {
		try {
			const errors = checkRequiredFields(getRequiredFields(), fields);
			setErrors(errors);

			if (!errors.length) {
				setLoading(true);

				// if (edit) {
				// 	const deletePromises = staffData.staffCategories.map((c) =>
				// 		StaffAPI.deleteCategory(id, c.playersCategoryId),
				// 	);
				// 	await Promise.all(deletePromises);

				// 	const addPromises = fields?.staffCategories.map((c) =>
				// 		StaffAPI.addCategory({
				// 			staffId: id,
				// 			playersCategoryId: c.value,
				// 			playersCategory: c.label,
				// 		}),
				// 	);
				// 	await Promise.all(addPromises);
				// }

				let body = Object.assign({}, fields);

				body = {
					...fields,
					dateOfBirth: fields.dateOfBirth
						? moment(fields.dateOfBirth).format("YYYY-MM-DD")
						: undefined,
					staffRole: {id: fields.staffRole.value, name: fields.staffRole.label},
					staffRoleId: fields.staffRole.value,
					staffCategories: fields?.staffCategories?.map((c) => {
						return {
							playersCategoryId: c.value,
							playersCategory: c.label,
						};
					}),
					clubId,
					userId,
					user: {userId, userName},
				};

				const response = edit
					? await StaffAPI.update(id, body)
					: await StaffAPI.create(body);

				setTimeout(() => {
					navigate(`/settings/staffs/${response?.id || id}`);
					setLoading(false);
					handleNotification(SUCCESSFUL_OPERATION, "success");
				}, 1000);
			}
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		saveStaff();
	};

	const getTextField = (
		fieldKey,
		label,
		required,
		values = fields,
		setValues = setFields,
	) => {
		return (
			<VLFTextField
				value={values[fieldKey] || ""}
				onChange={(e) => {
					required && setErrors([]);
					setValues({...values, [fieldKey]: e.target.value});
				}}
				label={label}
				placeholder={label}
				required={required}
				size="small"
				helperText={errors.indexOf(fieldKey) !== -1 ? REQUIRED_FIELD : ""}
				error={errors.indexOf(fieldKey) !== -1}
			/>
		);
	};

	const goBack = () => {
		if (edit) {
			navigate("/settings/staffs/" + id);
		} else {
			navigate("/settings/staffs");
		}
	};

	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: goBack,
			color: "info",
		},
		{
			icon: <SaveIcon />,
			title: "Salva",
			onClick: handleSubmit,
			disabled: checkRequiredFields(getRequiredFields(), fields).length
				? true
				: false,
		},
	];

	const breadcrumbsElements = [{label: "Staff", path: "/settings/staffs"}];
	if (edit) {
		breadcrumbsElements.push(
			{label: "/"},
			{
				label: fields
					? (fields.firstName || "") + " " + (fields.lastName || "")
					: "",
				path: "/settings/staffs/" + id,
			},
		);
	}

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="sheet-container">
				<div className="row no-margin">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Informazioni principali"
							content={
								<div className="row">
									<div className="col-sm-12 col-md-6">
										{getTextField("firstName", "Nome", true)}
									</div>
									<div className="col-sm-12 col-md-6">
										{getTextField("lastName", "Cognome", true)}
									</div>
									<div className="col-sm-12 col-md-6">
										<VLFDatePicker
											label="Data di nascita"
											value={fields.dateOfBirth}
											onChange={(value) => {
												setErrors([]);
												setFields({...fields, dateOfBirth: value});
											}}
											size="small"
										/>
									</div>
									<div className="col-sm-12 col-md-6">
										<VLFSelectNew
											options={staffRoles.map((sr) => {
												return {
													value: sr.id,
													label: sr.name,
												};
											})}
											value={fields.staffRole}
											onChange={(newValue) => {
												setErrors([]);
												setFields({...fields, staffRole: newValue});
											}}
											label="Ruolo"
											required
											helperText={
												errors.indexOf("staffRole") !== -1 ? REQUIRED_FIELD : ""
											}
											error={errors.indexOf("staffRole") !== -1}
										/>
									</div>
									<div className="col-sm-12 col-md-6">
										<VLFSelectNew
											options={categories}
											value={fields.staffCategories}
											onChange={(newValue) => {
												setErrors([]);
												setFields({...fields, staffCategories: newValue});
											}}
											label="Categoria"
											sortOrder="descend"
											isMulti
										/>
									</div>
								</div>
							}
							openable={false}
						/>
					</div>
				</div>
			</div>
		</Page>
	);
};

export default HandleStaffPage;
