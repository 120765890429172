import React, {useEffect, useState} from "react";

import {
	RadarChart,
	PolarGrid,
	PolarAngleAxis,
	PolarRadiusAxis,
	Radar,
	Tooltip,
} from "recharts";

import {getPlayerStructure} from "@/utils/functions";
import {
	initialValuationParameters,
	initialBaseValuationParameters,
} from "@/utils/constants";

import "./index.scss";

export default function PlayerRadarChart(props) {
	const {playerData, valuationsData} = props;

	const [radarData, setRadarData] = useState([]);
	useEffect(() => {
		if (valuationsData?.length) {
			const avgAreas = {};

			valuationsData.forEach((v) => {
				const parameters =
					parseInt(playerData?.playerCategoryName?.split(" ")?.[1]) > 12
						? initialValuationParameters
						: initialBaseValuationParameters;

				parameters.forEach((p) => {
					if (!avgAreas[p.area]) {
						avgAreas[p.area] = {};
					}

					avgAreas[p.area].total =
						(avgAreas[p.area].total || 0) + (v[p.fieldKeyRating] || 0);
					avgAreas[p.area].elements =
						(avgAreas[p.area].elements || 0) + (v[p.fieldKeyRating] ? 1 : 0);
				});
			});

			const radarData = [];
			Object.keys(avgAreas).forEach((area) => {
				radarData.push({
					subject: String(area).charAt(0).toUpperCase() + String(area).slice(1),
					A: avgAreas[area]?.elements
						? (avgAreas[area]?.total || 0) / avgAreas[area]?.elements
						: 0,
					fullMark: 10,
				});
			});

			let lastHeight,
				lastWeight = 0;
			playerData?.playersGrowth.forEach((pg) => {
				if (!lastHeight && pg.height) {
					lastHeight = pg.height;
				}

				if (!lastWeight && pg.weight) {
					lastWeight = pg.weight;
				}
			});

			radarData.push({
				subject: "Struttura",
				A: getPlayerStructure(
					playerData?.playerCategoryName?.split(" ")?.[1],
					lastHeight,
					lastWeight,
				),
				fullMark: 10,
			});

			setRadarData(radarData);
		}
	}, [valuationsData]);

	return (
		<div className="d-flex align-items-center justify-content-center h-100">
			{valuationsData?.length ? (
				<RadarChart outerRadius={100} width={500} height={250} data={radarData}>
					<PolarGrid />
					<PolarAngleAxis
						dataKey="subject"
						tick={{
							fill: "#333333",
							fontSize: 14,
							fontWeight: "bold",
						}}
					/>
					<PolarRadiusAxis
						angle={90}
						domain={[0, 10]}
						tick={{fill: "#555555", fontSize: 10}}
					/>
					<Radar
						name="Valore"
						dataKey="A"
						stroke="#1874cd"
						fill="#1874cd"
						fillOpacity={0.6}
					/>
					<Tooltip
						contentStyle={{
							backgroundColor: "#f5f5f5",
							borderColor: "#8884d8",
						}}
					/>
				</RadarChart>
			) : (
				<div style={{fontStyle: "italic", margin: "10px 0px"}}>
					Grafico non disponibile
				</div>
			)}
		</div>
	);
}
