import React, {useEffect, useState} from "react";
import {
	Routes,
	Route,
	useLocation,
	Navigate,
	useNavigate,
} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import Loading from "@/components/Loading";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import SideMenu from "@/components/SideMenu";
// import Page from "@/components/Page";

import AthleticTestsPage from "@/pages/Manager/AthleticTests";
import AthleticTestPage from "@/pages/Manager/AthleticTest";
import CategoriesPage from "@/pages/Categories";
import ClubsPage from "@/pages/Clubs";
import ExercisePage from "@/pages/Settings/Exercise";
import ExercisesPage from "@/pages/Settings/Exercises";
import HandleAthleticTestPage from "@/pages/Manager/HandleAthleticTest";
import HandleCategoryPage from "@/pages/HandleCategory";
import HandleClubPage from "@/pages/HandleClub";
import HandleExercisePage from "@/pages/Settings/HandleExercise";
import HandleMatchPage from "@/pages/Manager/HandleMatch";
import HandleMovementPage from "@/pages/Settings/HandleMovement";
import HandlePlayerPage from "@/pages/HandlePlayer";
import HandleScoutClubPage from "@/pages/Scout/Clubs/HandleClub";
import HandleScoutCoachPage from "@/pages/Scout/Coaches/HandleCoach";
import HandleScoutMatchPage from "@/pages/Scout/Matches/HandleMatch";
import HandleScoutPlayerPage from "@/pages/Scout/Players/HandlePlayer";
import HandleScoutReportPage from "@/pages/HandleScoutReport";
import HandleStaffPage from "@/pages/HandleStaff";
import HandleStaffRolePage from "@/pages/Settings/HandleStaffRole";
import HandleTargetPage from "@/pages/HandleTarget";
import HandleTQRPage from "@/pages/HandleTQR";
import HandleTrainingPage from "@/pages/Manager/HandleTraining";
import HandleUserPage from "@/pages/Settings/HandleUser";
import HandleUserRolePage from "@/pages/Settings/HandleUserRole";
import HandleValuationPage from "@/pages/Valuations/HandleValuation";
import HandleVideoPage from "@/pages/HandleVideo";
import HomePage from "@/pages/Home";
import LoginPage from "@/pages/Login";
import MatchPage from "@/pages/Manager/Match";
import MatchesPage from "@/pages/Matches";
import MatchesCalendarPage from "@/pages/MatchesCalendar";
import MovementsPage from "@/pages/Settings/Movements";
import PlayerPage from "@/pages/Player";
import PlayerProfilePage from "@/pages/PlayerProfile";
import PlayersPage from "@/pages/Players";
import RPEPage from "@/pages/RPE";
import ScoutClubsPage from "@/pages/Scout/Clubs";
import ScoutClubPage from "@/pages/Scout/Clubs/Club";
import ScoutCoachesPage from "@/pages/Scout/Coaches";
import ScoutCoachPage from "@/pages/Scout/Coaches/Coach";
import ScoutMatchesPage from "@/pages/Scout/Matches";
import ScoutMatchPage from "@/pages/Scout/Matches/Match";
import ScoutPlayersPage from "@/pages/Scout/Players";
import ScoutPlayerPage from "@/pages/Scout/Players/Player";
import ScoutReportsPage from "@/pages/ScoutReports";
import ScoutReportPage from "@/pages/ScoutReport";
import StaffPage from "@/pages/Staff";
import StaffsPage from "@/pages/Staffs";
import StaffRolePage from "@/pages/Settings/StaffRole";
import StaffRolesPage from "@/pages/Settings/StaffRoles";
import TargetPage from "@/pages/Target";
import TargetsPage from "@/pages/Targets";
import TQRPage from "@/pages/TQR";
import TrainingPage from "@/pages/Manager/Training";
import TrainingsPage from "@/pages/Manager/Trainings";
import UserRolePage from "@/pages/Settings/UserRole";
import UserRolesPage from "@/pages/Settings/UserRoles";
import UserPage from "@/pages/Settings/User";
import UsersPage from "@/pages/Settings/Users";
import ValuationPage from "@/pages/Valuations/Valuation";
import ValuationsPage from "@/pages/Valuations";
import VideoPage from "@/pages/Video";
import VideosPage from "@/pages/Videos";
import {AuthorizationAPI} from "@/api/authorization";
import {SeasonAPI} from "@/api/season";

import {
	loginSuccess,
	logout,
	userSuccess,
	setSelectedMenuItem as setGeneralSelectedMenuItem,
} from "@/actions";

import {theme} from "@/style/utils.js";
import {ThemeProvider} from "@mui/material/styles";

import {headerMenuItems, ERROR_OPERATION} from "@/utils/constants";

import "@/style/App.scss";

const documentHeight = () => {
	const doc = document.documentElement;
	doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
};

function Layout({children}) {
	const dispatch = useDispatch();

	const selectedMenuItem = useSelector((state) => state?.selectedMenuItem);
	const userRole = useSelector((state) => state?.user?.roleName);

	const location = useLocation();
	const navigate = useNavigate();

	const visibleHeaderMenuItems = headerMenuItems.filter(
		(item) => item.roles.indexOf(userRole?.toLowerCase()) !== -1,
	);

	useEffect(() => {
		if (userRole) {
			const currentPath = location.pathname.split("/")?.[1];
			const selectedItem =
				currentPath &&
				visibleHeaderMenuItems.map((i) => i.value).indexOf(currentPath) !== -1
					? currentPath
					: visibleHeaderMenuItems[0]?.value;
			dispatch(setGeneralSelectedMenuItem(selectedItem));
		}
	}, [userRole]);

	if (!userRole || !selectedMenuItem) {
		return <Loading visible />;
	}

	return (
		<div className="layout-container">
			<div className="layout">
				<div className="layout-side d-flex">
					<div className="header-menu-container d-flex align-items-center w-100">
						<div className="logo" onClick={() => navigate("/")} />
						<div className="header-menu-items">
							{visibleHeaderMenuItems.map((item, key) => {
								return (
									<div
										key={key}
										className={
											"header-menu-item" +
											(selectedMenuItem === item.value ? " selected" : "")
										}
										onClick={() => {
											if (location.pathname.split("/")?.[1] !== item.value) {
												navigate("/");
											}
											dispatch(setGeneralSelectedMenuItem(item.value));
										}}
									>
										<img className="item-icon" src={item.image} alt="" />
										<span className="item-label">{item.label}</span>
									</div>
								);
							})}
						</div>
					</div>
					<div className="d-flex">
						<SideMenu />
						{children}
					</div>
				</div>
			</div>
		</div>
	);
}

function RequireAuth({isLoggedIn, handleNotification, children}) {
	let location = useLocation();

	if (!isLoggedIn) {
		return <Navigate to="/login" state={{from: location}} replace />;
	}

	const childWithProps = React.Children.only(children);

	return (
		<ThemeProvider theme={theme}>
			<Layout>
				{React.cloneElement(childWithProps, {handleNotification})}
			</Layout>
		</ThemeProvider>
	);
}

function App() {
	const dispatch = useDispatch();

	const isLoggedIn = useSelector((state) => state.isLoggedIn);

	const [loading, setLoading] = useState(true);
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState("");
	const [severity, setSeverity] = useState("info");

	const handleClose = () => {
		setOpen(false);
	};

	const handleNotification = (newMessage, newSeverity = "info") => {
		setMessage(newMessage);
		setSeverity(newSeverity);
		setOpen(true);
	};

	useEffect(() => {
		if (isLoggedIn) {
			const getUserData = async () => {
				try {
					const response = await AuthorizationAPI.getUserData();
					const currentSeason = await SeasonAPI.current();
					dispatch(userSuccess({user: response, season: currentSeason}));
				} catch (error) {
					handleNotification(ERROR_OPERATION, "error");
				}
			};

			getUserData();
		}
	}, [dispatch, isLoggedIn]);

	useEffect(() => {
		window.addEventListener("resize", documentHeight);
		documentHeight();

		const checkToken = async (token) => {
			try {
				await AuthorizationAPI.verifyToken(token);
				dispatch(loginSuccess(token));
			} catch (error) {
				dispatch(logout());
			} finally {
				setLoading(false);
			}
		};

		const savedToken = localStorage.getItem("token");
		if (savedToken) {
			setLoading(true);
			checkToken(savedToken);
		} else {
			setLoading(false);
		}
	}, [dispatch]);

	const authRoutes = [
		{component: <HomePage />, path: "/"},
		{component: <StaffsPage />, path: "/settings/staffs"},
		{component: <StaffPage />, path: "/settings/staffs/:id"},
		{component: <HandleStaffPage />, path: "/settings/staffs/new"},
		{component: <HandleStaffPage edit />, path: "/settings/staffs/:id/edit"},
		{component: <PlayersPage />, path: "/players"},
		{component: <PlayerPage />, path: "/players/:id"},
		{component: <HandlePlayerPage />, path: "/players/new"},
		{component: <HandlePlayerPage edit />, path: "/players/:id/edit"},
		{component: <ValuationPage />, path: "/players-valuations/:id"},
		{component: <HandleValuationPage />, path: "/players-valuations/new"},
		{
			component: <HandleValuationPage edit />,
			path: "/players-valuations/:id/edit",
		},
		{
			component: <ValuationsPage external fullpage />,
			path: "/scout/valuations",
		},
		{component: <ValuationPage external />, path: "/scout/valuations/:id"},
		{
			component: <HandleValuationPage external />,
			path: "/scout/valuations/new",
		},
		{
			component: <HandleValuationPage edit external />,
			path: "/scout/valuations/:id/edit",
		},
		{component: <CategoriesPage />, path: "/settings/categories"},
		{
			component: <HandleCategoryPage />,
			path: "/settings/categories/new",
		},
		{
			component: <HandleCategoryPage edit />,
			path: "/settings/categories/:id/edit",
		},
		{component: <ClubsPage />, path: "/settings/clubs"},
		{component: <HandleClubPage />, path: "/settings/clubs/new"},
		{
			component: <HandleClubPage edit />,
			path: "/settings/clubs/:id/edit",
		},
		...["/trainings"].map((path) => ({
			path,
			component: <TrainingsPage />,
		})),
		...["/trainings/:id"].map((path) => ({
			path,
			component: <TrainingPage />,
		})),
		...["/trainings/new"].map((path) => ({
			path,
			component: <HandleTrainingPage />,
		})),
		...["/trainings/admin/new"].map((path) => ({
			path,
			component: <HandleTrainingPage admin />,
		})),
		...["/trainings/:id/edit"].map((path) => ({
			path,
			component: <HandleTrainingPage edit />,
		})),
		...["/matches"].map((path) => ({
			path,
			component: <MatchesPage />,
		})),
		...["/match-calendar"].map((path) => ({
			path,
			component: <MatchesCalendarPage />,
		})),
		...["/matches/:id"].map((path) => ({
			path,
			component: <MatchPage />,
		})),
		...["/matches/new"].map((path) => ({
			path,
			component: <HandleMatchPage />,
		})),
		...["/matches/:id/edit"].map((path) => ({
			path,
			component: <HandleMatchPage edit />,
		})),
		{component: <TQRPage />, path: "/measurations/tqr"},
		{component: <RPEPage />, path: "/measurations/rpe"},
		{component: <HandleTQRPage />, path: "/measurations/tqr/new"},
		{component: <AthleticTestsPage />, path: "/athletic-tests"},
		{component: <AthleticTestPage />, path: "/athletic-tests/:id"},
		{component: <HandleAthleticTestPage />, path: "/athletic-tests/new"},
		{
			component: <HandleAthleticTestPage edit />,
			path: "/athletic-tests/:id/edit",
		},
		{component: <VideosPage />, path: "/video"},
		{component: <VideoPage />, path: "/video/:id"},
		{component: <HandleVideoPage />, path: "/video/new"},
		{component: <HandleVideoPage edit />, path: "/video/:id/edit"},
		{component: <TargetsPage />, path: "/manager/targets"},
		{component: <TargetPage />, path: "/manager/targets/:id"},
		{component: <HandleTargetPage />, path: "/manager/targets/new"},
		{component: <HandleTargetPage edit />, path: "/manager/targets/:id/edit"},
		{component: <ScoutReportsPage />, path: "/scout/reports"},
		{component: <ScoutReportPage />, path: "/scout/reports/:id"},
		{component: <HandleScoutReportPage />, path: "/scout/reports/new"},
		{
			component: <HandleScoutReportPage edit />,
			path: "/scout/reports/:id/edit",
		},
		{component: <ScoutMatchesPage />, path: "/scout-matches"},
		{component: <ScoutMatchPage />, path: "/scout-matches/:id"},
		{component: <HandleScoutMatchPage edit />, path: "/scout-matches/:id/edit"},
		{component: <HandleScoutMatchPage />, path: "/scout-matches/new"},
		{component: <ScoutClubsPage />, path: "/scout-clubs"},
		{component: <ScoutClubPage />, path: "/scout-clubs/:id"},
		{component: <HandleScoutClubPage edit />, path: "/scout-clubs/:id/edit"},
		{component: <HandleScoutClubPage />, path: "/scout-clubs/new"},
		{component: <ScoutCoachesPage />, path: "/scout-coaches"},
		{component: <ScoutCoachPage />, path: "/scout-coaches/:id"},
		{component: <HandleScoutCoachPage edit />, path: "/scout-coaches/:id/edit"},
		{component: <HandleScoutCoachPage />, path: "/scout-coaches/new"},

		{component: <ScoutPlayersPage />, path: "/scout-players"},
		{component: <ScoutPlayerPage />, path: "/scout-players/:id"},
		{
			component: <HandleScoutPlayerPage edit />,
			path: "/scout-players/:id/edit",
		},
		{component: <HandleScoutPlayerPage />, path: "/scout-players/new"},

		{component: <StaffRolesPage />, path: "/settings/staff-roles"},
		{component: <StaffRolePage />, path: "/settings/staff-roles/:id"},
		{component: <HandleStaffRolePage />, path: "/settings/staff-roles/new"},
		{
			component: <HandleStaffRolePage edit />,
			path: "/settings/staff-roles/:id/edit",
		},
		{component: <UsersPage />, path: "/settings/users"},
		{component: <UserPage />, path: "/settings/users/:id"},
		{component: <HandleUserPage />, path: "/settings/users/new"},
		{component: <UserRolesPage />, path: "/settings/user-roles"},
		{component: <UserRolePage />, path: "/settings/user-roles/:id"},
		{component: <HandleUserRolePage />, path: "/settings/user-roles/new"},
		{component: <ExercisesPage />, path: "/exercises"},
		{component: <ExercisePage />, path: "/exercises/:id"},
		{component: <HandleExercisePage />, path: "/exercises/new"},
		{
			component: <HandleExercisePage edit />,
			path: "/exercises/:id/edit",
		},
		{component: <MovementsPage />, path: "/player-movements"},
		{component: <HandleMovementPage />, path: "/player-movements/new"},
		{
			component: <HandleMovementPage edit />,
			path: "/player-movements/:id/edit",
		},
		{component: <PlayerProfilePage />, path: "/profile/:id"},
	];

	if (loading) {
		return <Loading />;
	}

	return (
		<>
			<Routes>
				<Route>
					<Route
						path="/login"
						element={<LoginPage handleNotification={handleNotification} />}
					/>
					{authRoutes.map((route, key) => {
						return (
							<Route
								key={key}
								path={route.path}
								exact
								element={
									<RequireAuth
										isLoggedIn={isLoggedIn}
										handleNotification={handleNotification}
									>
										{route.component}
									</RequireAuth>
								}
							/>
						);
					})}
				</Route>
			</Routes>
			<Snackbar
				open={open}
				autoHideDuration={6000}
				anchorOrigin={{vertical: "bottom", horizontal: "right"}}
				onClose={handleClose}
			>
				<Alert severity={severity}>
					<div>{message}</div>
				</Alert>
			</Snackbar>
		</>
	);
}

export default App;
