import api from "./configuration";
import apiUrl from "./url";

const USE_AUTH_INTERCEPTOR = true;

export const MatchObservedAPI = {
	list: async (filtersData) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/MatchesObserved",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			params: filtersData,
		});

		return response.data;
	},
	create: async (data) => {
		const response = await api.request({
			method: "POST",
			url: apiUrl + "/MatchesObserved",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	getById: async (id) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/MatchesObserved/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	delete: async (id) => {
		const response = await api.request({
			method: "DELETE",
			url: apiUrl + "/MatchesObserved/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	update: async (id, data) => {
		const response = await api.request({
			method: "PUT",
			url: apiUrl + "/MatchesObserved/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	upload: async (id, file) => {
		const formData = new FormData();
		formData.append("file", file);

		const response = await api.request({
			method: "POST",
			url: apiUrl + "/MatchesObserved/" + id + "/upload",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data: formData,
			headers: {"Content-Type": "multipart/form-data"},
		});

		return response.data;
	},
	getNotes: async (id) => {
		const response = await api.request({
			method: "GET",
			url: apiUrl + "/MatchesObserved/" + id + "/notes",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
	createNote: async (data) => {
		const response = await api.request({
			method: "POST",
			url: apiUrl + "/MatchesObserved/notes",
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	updateNote: async (matchId, id, data) => {
		const response = await api.request({
			method: "PUT",
			url: apiUrl + "/MatchesObserved/" + matchId + "/notes/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
			data,
		});

		return response.data;
	},
	deleteNote: async (matchId, id) => {
		const response = await api.request({
			method: "DELETE",
			url: apiUrl + "/MatchesObserved/" + matchId + "/notes/" + id,
			useAuthInterceptor: USE_AUTH_INTERCEPTOR,
		});

		return response.data;
	},
};
