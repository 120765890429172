import React, {useState} from "react";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import EmptyData from "@/components/EmptyData";
import VLFModal from "@/components/Modal";
import VLFUpload from "@/components/Input/Upload";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";

import {AttachmentAPI} from "@/api/attachment";

import {ERROR_OPERATION, SUCCESSFUL_OPERATION} from "@/utils/constants";

import "./index.scss";

// verificare se il file è un video
const isVideoFile = (filePath) => {
	const extension = filePath.split(".").pop().toLowerCase();
	return ["mp4", "mov", "avi"].includes(extension);
};

// verificare se il file è un'immagine
const isImageFile = (filePath) => {
	const extension = filePath.split(".").pop().toLowerCase();
	return ["jpg", "jpeg", "png", "gif"].includes(extension);
};

export default function AttachmentsContainer(props) {
	const {
		attachments,
		entityType,
		entityId,
		handleAdd,
		handleDownload,
		handleDelete,
		handleNotification,
		editable = true,
	} = props;

	const [loading, setLoading] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);

	let apiUrl;
	switch (process.env.REACT_APP_ENV) {
		default:
		case "development":
			apiUrl = "http://www.vlfsportsperformance.it";
			break;
		case "production":
			apiUrl = "https://goalplayerslab.it";
			break;
	}

	const uploadFile = async () => {
		setLoading(true);
		try {
			await AttachmentAPI.upload(
				entityType,
				entityId,
				selectedFile,
				selectedFile.name,
			);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			handleAdd();
			setOpenModal(false);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const getRelativePath = (absolutePath) => {
		const relativePath = absolutePath.split("\\uploads\\")[1];
		return relativePath
			? `${apiUrl}/uploads/${relativePath.replace(/\\/g, "/")}`
			: absolutePath;
	};

	const renderAttachmentPreview = (attachment) => {
		const filePath = getRelativePath(attachment.filePath);

		if (isImageFile(attachment.filePath)) {
			return (
				<img
					className="attachment-img"
					src={filePath}
					alt=""
					style={{
						maxHeight: "80px",
						maxWidth: "175px",
						objectFit: "cover",
					}}
				/>
			);
		} else if (isVideoFile(attachment.filePath)) {
			return (
				<video width="100%" height="200px" controls>
					<source src={filePath} type="video/mp4" />
					Il tuo browser non supporta la riproduzione dei video.
				</video>
			);
		} else {
			return (
				<img
					className="attachment-img pdf"
					src={require("@/assets/images/file.png")}
					alt=""
				/>
			);
		}
	};

	return (
		<>
			{editable && (
				<div className="d-flex align-items-center justify-content-end mb-2 mx-2">
					<Button
						variant="contained"
						onClick={() => {
							setOpenModal(true);
							setSelectedFile(null);
						}}
						size="small"
					>
						<div className="d-flex align-items-center">
							<div>Aggiungi</div>
						</div>
					</Button>
				</div>
			)}
			<div className="row no-margin attachments-container">
				{attachments?.length ? (
					attachments.map(
						(attachment, key) =>
							attachment && (
								<div
									key={key}
									className={"col-sm-12 col-lg-6 col-xl-4 padding-small"}
								>
									<div
										className={
											"attachment-box" +
											(isVideoFile(attachment.filePath) ? " video" : "") +
											(isImageFile(attachment.filePath) ? " image" : "")
										}
									>
										{renderAttachmentPreview(attachment)}
										<div className="attachment-info d-flex align-items-center px-2 py-2 w-100">
											<div className="attachment-name">
												{attachment.name ||
													attachment.filePath.split("\\").pop()}
											</div>
											<div className="attachment-actions">
												<div className="single-action">
													<Button
														className="single-action"
														variant="outlined"
														size="small"
														onClick={() => handleDownload(attachment.id)}
													>
														<DownloadIcon />
													</Button>
												</div>
												{editable && (
													<div className="single-action">
														<Button
															variant="outlined"
															color="error"
															size="small"
															onClick={() => handleDelete(attachment.id)}
														>
															<DeleteIcon />
														</Button>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
							),
					)
				) : (
					<EmptyData />
				)}
				{/* Area di visualizzazione del video */}
				{/* {selectedFile && isVideoFile(selectedFile.name) && (
					<div className="video-container">
						<video id="video-preview" width="400" height="300" controls>
							<source
								src={URL.createObjectURL(selectedFile)}
								type="video/mp4"
							/>
							Il tuo browser non supporta la riproduzione dei video.
						</video>
					</div>
				)} */}
			</div>
			<VLFModal
				open={openModal}
				title="Aggiungi file multimediale"
				content={
					<div className="row mt-2 no-margin">
						<div className="col-sm-12 padding-small">
							{loading ? (
								<div className="d-flex align-items-center justify-content-center">
									<CircularProgress />
								</div>
							) : (
								<VLFUpload
									selectedFile={selectedFile}
									setSelectedFile={setSelectedFile}
								/>
							)}
						</div>
					</div>
				}
				confirm={uploadFile}
				close={() => setOpenModal(false)}
				confirmLabel="Conferma"
				disabled={loading}
			/>
		</>
	);
}
