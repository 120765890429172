import * as React from "react";

import "./index.scss";

export default function StaffCard(props) {
	const {element} = props;

	const sortedCategories =
		element.staffCategories
			?.slice()
			.sort((a, b) => a.playersCategory?.localeCompare(b.playersCategory)) ||
		[];

	const renderedCategories = (
		<div className="categories-list">
			{sortedCategories.map((c, k) => {
				return (
					<div className="categories-element" key={k}>
						{c?.playersCategory}
					</div>
				);
			})}
		</div>
	);

	return (
		<div className="staff-card-container">
			<div className="staff-card-header">
				<div className="top-left">{element.role}</div>
				<div className="top-right">{renderedCategories}</div>
			</div>
			<div className="staff-card-info">
				<div className="info-title">
					{element.firstName + " " + element.lastName}
				</div>
			</div>
			<div className="separator"></div>
			{sortedCategories?.length ? (
				<div className="staff-card-preview">{renderedCategories}</div>
			) : null}
		</div>
	);
}
