import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import moment from "moment";

import Loading from "@/components/Loading";
import Page from "@/components/Page";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";
import ViewField from "@/components/Input/ViewField";
import VLFModal from "@/components/Modal";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import {StaffAPI} from "@/api/staff";

import {
	dateFormat,
	ERROR_OPERATION,
	SUCCESSFUL_OPERATION,
} from "@/utils/constants";

import "./index.scss";

function StaffPage(props) {
	const {handleNotification} = props;

	const {id} = useParams();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [openHandleConfirm, setOpenHandleConfirm] = useState(false);
	const [staffData, setStaffData] = useState({});

	const getStaff = async () => {
		setLoading(true);
		try {
			const response = await StaffAPI.getById(id);
			setStaffData(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getStaff();
	}, []);

	const deleteStaff = async () => {
		setLoading(true);
		try {
			await StaffAPI.delete(staffData?.id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			navigate("/settings/staffs");
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		} finally {
			setOpenHandleConfirm(false);
		}
	};

	const mainConfiguration = [
		{value: staffData?.firstName, label: "Nome", size: "col-6 col-lg-4"},
		{
			value: staffData?.lastName,
			label: "Cognome",
			size: "col-6 col-lg-4",
		},
		{
			value: staffData?.dateOfBirth
				? moment(staffData?.dateOfBirth).format(dateFormat)
				: null,
			label: "Data di nascita",
			size: "col-6 col-lg-4",
		},
		{value: staffData?.staffRole?.name, label: "Ruolo", size: "col-6 col-lg-4"},
		{
			value: (
				<div className="categories-list">
					{staffData?.staffCategories
						?.sort((a, b) =>
							a.playersCategory?.localeCompare(b.playersCategory),
						)
						.map((c, k) => {
							return (
								<div className="categories-element" key={k}>
									{c.playersCategory}
								</div>
							);
						})}
					{!staffData?.staffCategories?.length && <div>---</div>}
				</div>
			),
			label: "Categorie",
			size: "col-6 col-lg-4",
		},
	];

	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: () => navigate("/settings/staffs"),
			color: "info",
		},
		{
			icon: <EditIcon />,
			title: "Modifica",
			onClick: () => navigate("/settings/staffs/" + staffData?.id + "/edit"),
		},
		{
			icon: <DeleteIcon />,
			title: "Elimina",
			onClick: () => setOpenHandleConfirm(true),
			color: "error",
		},
	];

	const breadcrumbsElements = [
		{label: "Staff", path: "/settings/staffs"},
		{label: "/"},
	];

	return (
		<Page className="player-container">
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				current={
					staffData
						? (staffData?.firstName || "") + " " + (staffData?.lastName || "")
						: ""
				}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="sheet-container">
				<div className="row no-margin">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Informazioni principali"
							content={
								<div className="row">
									<div className="col-sm-12 col-md-2">
										{staffData?.id && (
											<div
												className={
													"image-container" +
													(!staffData.imageUrl ? " generic" : "")
												}
											>
												<img
													src={
														!staffData.imageUrl
															? require("@/assets/images/manager/staff.png")
															: "https://goalplayerslab.it/uploads/profile/staff/" +
																staffData.id?.toString() +
																"/" +
																staffData.imageUrl
													}
													alt=""
												/>
											</div>
										)}
									</div>
									<div className="col-sm-12 col-md-10">
										<ViewField configuration={mainConfiguration} />
									</div>
								</div>
							}
							openable={false}
						/>
					</div>
				</div>
			</div>
			<VLFModal
				open={openHandleConfirm}
				title="Eliminazione"
				content={
					<div className="mt-2">
						Sei sicuro di voler eliminare questo elemento?
					</div>
				}
				confirm={() => deleteStaff()}
				close={() => setOpenHandleConfirm(false)}
				confirmLabel="Conferma"
			/>
		</Page>
	);
}

export default StaffPage;
