import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import moment from "moment";

import Loading from "@/components/Loading";
import Page from "@/components/Page";
import Breadcrumbs from "@/components/Breadcrumbs";
import EmptyData from "@/components/EmptyData";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";
import ViewField from "@/components/Input/ViewField";
import Rating from "@/components/Rating";
import VLFModal from "@/components/Modal";
import AttachmentsContainer from "@/components/AttachmentsContainer";
import Button from "@mui/material/Button";
import HandleAttendanceModal from "@/pages/Manager/Match/handleAttendance";
import HandleExerciseModal from "@/pages/Manager/Match/handleExercise";
import HandleRPEModal from "@/pages/Manager/Match/handleRPE";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import RefreshIcon from "@mui/icons-material/Refresh";

import {ExerciseAPI} from "@/api/exercise";
import {TrainingAPI} from "@/api/training";
import {AttachmentAPI} from "@/api/attachment";

import {
	ERROR_OPERATION,
	SUCCESSFUL_OPERATION,
	rpeOptions,
	attendanceStatusOptions,
} from "@/utils/constants";
import {usePlayers} from "@/utils/hooks";

import "./index.scss";

function TrainingPage(props) {
	const {handleNotification} = props;

	const {id} = useParams();
	const navigate = useNavigate();

	const playerId = useSelector((state) => state?.user?.playerId);
	const userRole = useSelector((state) => state?.user?.roleName);

	const [loading, setLoading] = useState(false);
	const [openHandleConfirm, setOpenHandleConfirm] = useState(false);
	const [trainingData, setTrainingData] = useState({});
	const [attachments, setAttachments] = useState([]);
	const [entityToDelete, setEntityToDelete] = useState({});

	const [openAttendanceModal, setOpenAttendanceModal] = useState(false);
	const [openExerciseModal, setOpenExerciseModal] = useState(false);
	const [openRPEModal, setOpenRPEModal] = useState(false);
	const [initialData, setInitialData] = useState();

	const [formData, setFormData] = useState({});

	const {players} = usePlayers({setLoading, handleNotification});
	const [attendance, setAttendance] = useState("");

	const getTraining = async () => {
		setLoading(true);
		try {
			const response = await TrainingAPI.getById(id);
			setTrainingData(response);

			if (userRole === "Player") {
				const playerMeasurement =
					response.trainingRPE?.filter((ps) => ps.playerId === playerId)?.[0] ||
					{};
				setFormData({
					...Object.assign({}, playerMeasurement),
					rpe: parseInt(playerMeasurement.rpe),
				});

				const playerConvocation = response.trainingConvocations?.filter(
					(c) => c.playerId === playerId,
				)?.[0];

				setAttendance(playerConvocation ? playerConvocation.status : undefined);
				setLoading(false);
			}
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		}
	};
	const deleteTraining = async () => {
		setLoading(true);
		try {
			await TrainingAPI.delete(trainingData?.id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			navigate("/trainings");
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		} finally {
			setOpenHandleConfirm(false);
		}
	};

	const getTrainingAttachment = async () => {
		try {
			const response = await AttachmentAPI.list("training", id);
			setAttachments(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};
	const getAttachment = async (id) => {
		setLoading(true);
		try {
			const currentFile = attachments.filter((a) => a.id === id)?.[0];
			const response = await AttachmentAPI.getById(id, {
				responseType: "blob",
			});

			// Ottieni l'estensione del file
			const extension = currentFile.filePath
				.split("\\")
				.pop()
				.split(".")
				.pop()
				.toLowerCase();
			let mimeType = "image/jpeg"; // Default immagine

			// eslint-disable-next-line default-case
			switch (extension) {
				case "pdf":
					mimeType = "application/pdf";
					break;
				case "png":
					mimeType = "image/png";
					break;
				case "jpg":
				case "jpeg":
					mimeType = "image/jpeg";
					break;
				case "doc":
					mimeType = "doc";
					break;
			}

			const blob = new Blob([response], {type: mimeType});

			const url = window.URL.createObjectURL(blob);

			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", currentFile.filePath.split("\\").pop());

			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			handleNotification(SUCCESSFUL_OPERATION, "success");
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};
	const deleteAttachment = async () => {
		setLoading(true);
		if (entityToDelete?.id) {
			try {
				await AttachmentAPI.delete(entityToDelete?.id);
				handleNotification(SUCCESSFUL_OPERATION, "success");
				setOpenHandleConfirm(false);
				getTrainingAttachment();
			} catch (error) {
				handleNotification(ERROR_OPERATION, "error");
			} finally {
				setLoading(false);
			}
		} else {
			return;
		}
	};

	const [trainingExercises, setTrainingExercises] = useState([]);
	const getTrainingExercises = async () => {
		try {
			const response = await TrainingAPI.getDetails(id);
			setTrainingExercises(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};
	useEffect(() => {
		getTraining();
		getTrainingAttachment();
		getTrainingExercises();
	}, []);

	const handleExercise = async (data, exerciseId) => {
		setLoading(true);
		try {
			let body = {
				trainingId: id,
			};

			if (data.exercise) {
				body = {
					...body,
					...data,
					exerciseId: data?.exercise?.value,
				};
				delete body.exercise;
			} else {
				const response = await ExerciseAPI.create({
					...data,
					description: data.description || "",
					exerciseType: data.exerciseType || "",
				});

				body = {
					...body,
					...data,
					exerciseId: response.id,
				};
			}

			exerciseId
				? await TrainingAPI.updateDetail(trainingData?.id, exerciseId, body)
				: await TrainingAPI.createDetail(body);
			getTrainingExercises();
			handleClose();
			handleNotification(SUCCESSFUL_OPERATION, "success");
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};
	const deleteExercise = async () => {
		setLoading(true);
		try {
			await TrainingAPI.deleteDetail(trainingData?.id, entityToDelete?.id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			setOpenHandleConfirm(false);
			getTrainingExercises();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const handleAttendance = async (data, id) => {
		setLoading(true);
		try {
			const body = playerId
				? data
				: {
						trainingId: trainingData.id,
						...data,
						playerId: data.player?.value,
						status: data.status?.value,
					};

			delete body.player;

			id
				? await TrainingAPI.updateAttendance(trainingData?.id, id, body)
				: await TrainingAPI.createAttendance(body);
			getTraining();
			handleClose();
			handleNotification(SUCCESSFUL_OPERATION, "success");
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};
	const deleteAttendance = async () => {
		setLoading(true);
		try {
			await TrainingAPI.deleteAttendance(trainingData?.id, entityToDelete?.id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			setOpenHandleConfirm(false);
			getTraining();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const handleMeasurement = async (data, id) => {
		setLoading(true);
		try {
			const rpe =
				userRole === "Player"
					? data.rpe || undefined
					: data.rpe.value || undefined;

			if (rpe) {
				const body = {
					trainingId: trainingData?.id,
					playerId: userRole === "Player" ? playerId : data.player.value,
					...data,
					rpe,
				};
				id
					? await TrainingAPI.updateMeasurement(trainingData?.id, id, body)
					: await TrainingAPI.createMeasurement(body);
			}

			if (userRole === "Player") {
				const playerConvocation = trainingData?.trainingConvocations?.filter(
					(c) => c.playerId === playerId,
				)?.[0];

				await handleAttendance(
					{
						id: playerConvocation?.id,
						trainingId: trainingData?.id,
						playerId,
						status: rpe ? attendanceStatusOptions[0].label : attendance,
					},
					playerConvocation?.id,
				);
				navigate("/trainings");
			} else {
				getTraining();
				handleClose();
			}
			handleNotification(SUCCESSFUL_OPERATION, "success");
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};
	const deleteMeasurement = async () => {
		setLoading(true);
		try {
			await TrainingAPI.deleteMeasurement(trainingData?.id, entityToDelete?.id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			setOpenHandleConfirm(false);
			getTraining();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const mainConfiguration = [
		{
			value: trainingData?.playersCategory,
			label: "Categoria",
			size: "col-6 col-lg-4",
		},
		{
			value:
				trainingData?.startDate &&
				moment(trainingData?.startDate).format("DD/MM/YYYY"),
			label: "Data",
			size: "col-6 col-lg-4",
		},
		{
			value: trainingData?.fieldName,
			label: "Campo",
			size: "col-6 col-lg-4",
		},
		{
			value:
				trainingData?.startDate &&
				moment(trainingData?.startDate).format("HH:mm"),
			label: "Inizio",
			size: "col-6 col-lg-4",
		},
		{
			value:
				trainingData?.endDate && moment(trainingData?.endDate).format("HH:mm"),
			label: "Fine",
			size: "col-6 col-lg-4",
		},
	];

	const getAttendanceStatusIcon = (status) => {
		return status === "Presente" ? "on" : "off";
	};

	const handleClose = () => {
		setOpenAttendanceModal(false);
		setOpenExerciseModal(false);
		setOpenRPEModal(false);
		setInitialData();
	};

	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: () => navigate("/trainings"),
			color: "info",
		},
	];

	if (userRole === "Player") {
		mainActions.push({
			icon: <SaveIcon />,
			title: "Salva",
			onClick: () => handleMeasurement(formData, formData?.id),
			disabled: !formData.rpe && !attendance,
		});
	} else {
		mainActions.push({
			icon: <DeleteIcon />,
			title: "Elimina",
			onClick: () => {
				setEntityToDelete({type: "training", id});
				setOpenHandleConfirm(true);
			},
			color: "error",
		});
	}

	const breadcrumbsElements = [
		{label: "Allenamenti", path: "/trainings"},
		{label: "/"},
	];

	const hasRPE =
		parseInt(trainingData?.playersCategory?.split(" ")?.[1]) >= 13 ||
		trainingData?.playersCategory === "Prima Squadra";

	return (
		<Page className="player-container">
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				current={
					trainingData
						? moment(trainingData.startDate).format("DD/MM/YYYY HH:mm") +
							" - " +
							moment(trainingData.endDate).format("HH:mm")
						: ""
				}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="sheet-container">
				<div className="row no-margin">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Informazioni principali"
							content={
								<div className="row">
									<div className="col-sm-12 col-md-2">
										<div className="image-container generic">
											<img
												src={require("@/assets/images/coach/training.png")}
												alt=""
											/>
										</div>
									</div>
									<div className="col-sm-12 col-md-10">
										<ViewField configuration={mainConfiguration} />
									</div>
								</div>
							}
							openable={false}
						/>
					</div>
				</div>
				{userRole !== "Player" && (
					<div className="row no-margin">
						<div className="col-sm-12 padding-small mt-2">
							<VLFCard
								title="Presenze"
								content={
									<>
										<div className="d-flex align-items-center justify-content-end mb-2">
											<Button
												variant="contained"
												onClick={() => {
													setOpenAttendanceModal(true);
												}}
												size="small"
											>
												<div className="d-flex align-items-center">
													<div>Aggiungi</div>
												</div>
											</Button>
										</div>
										<div className="row">
											{trainingData?.trainingConvocations?.map((mc, k) => {
												const currentPlayer = players.filter(
													(p) => p.id === mc.playerId,
												)?.[0];
												return (
													<div
														key={k}
														className="col-12 col-xl-6 padding-small"
													>
														<div className="d-flex flex-column gap-2">
															<div className="stats-player-container attendance mt-1">
																<div className="d-flex align-items-center gap-2">
																	<img
																		className="player-icon"
																		src={require("@/assets/images/manager/player.png")}
																		alt=""
																	/>
																	<div
																		className="player-name clickable match"
																		onClick={() =>
																			navigate("/players/" + mc.playerId)
																		}
																	>
																		{currentPlayer?.firstName +
																			" " +
																			currentPlayer?.lastName}
																	</div>
																</div>
																<div className="stats-container d-flex align-items-center">
																	<div className="player-stat-container flex-row gap-2">
																		<div className="stat-label">
																			{mc.status || "---"}
																		</div>
																		<div
																			className={
																				"stat-label-icon " +
																				getAttendanceStatusIcon(mc.status)
																			}
																		/>
																	</div>
																</div>
																<div className="d-flex align-items-center gap-2">
																	<Button
																		variant={"outlined"}
																		onClick={() => {
																			setInitialData({
																				id: mc.id,
																				playerId: mc.playerId,
																				status: mc.status,
																			});
																			setOpenAttendanceModal(true);
																		}}
																		size="small"
																	>
																		<div className="action-content d-flex align-items-center">
																			<RefreshIcon />
																		</div>
																	</Button>
																	<Button
																		variant={"outlined"}
																		onClick={() => {
																			setEntityToDelete({
																				type: "attendance",
																				id: mc.id,
																			});
																			setOpenHandleConfirm(true);
																		}}
																		size="small"
																		color="error"
																	>
																		<div className="action-content d-flex align-items-center">
																			<DeleteIcon />
																		</div>
																	</Button>
																</div>
															</div>
														</div>
													</div>
												);
											})}
											{!trainingData?.trainingConvocations?.length && (
												<EmptyData />
											)}
										</div>
									</>
								}
							/>
						</div>
					</div>
				)}
				{userRole !== "Player" && (
					<div className="row no-margin">
						<div className="col-sm-12 padding-small mt-2">
							<VLFCard
								title="Esercizi"
								content={
									<>
										<div className="d-flex align-items-center justify-content-end mb-2">
											<Button
												variant="contained"
												onClick={() => {
													setOpenExerciseModal(true);
												}}
												size="small"
											>
												<div className="d-flex align-items-center">
													<div>Aggiungi</div>
												</div>
											</Button>
										</div>
										<div className="row">
											{trainingExercises?.map((te, k) => {
												return (
													<div key={k} className="col-12 padding-small mt-2">
														{/* <div className="d-flex flex-column"> */}
														<div className="exercise-container">
															<div className="exercise-counter">{k + 1}</div>
															<div className="exercise-text-container">
																<div className="exercise-title-container">
																	<img
																		src={require("@/assets/images/manager/exercise.png")}
																		alt=""
																	/>
																	<div className="exercise-title">
																		{te.exercise?.name}
																	</div>
																</div>
																<div className="exercise-notes">
																	{te.description}
																</div>
															</div>
															<div className="exercise-duration">
																<img
																	src={require("@/assets/images/manager/time.png")}
																	alt=""
																/>
																<div className="exercise-duration-text">
																	{te.duration ? te.duration + "'" : "---"}
																</div>
															</div>
															<div className="d-flex align-items-center gap-2">
																{/* <Button
																		variant={"outlined"}
																		onClick={() => {
																			setInitialData({
																				id: mc.id,
																				playerId: mc.playerId,
																				status: mc.status,
																			});
																			setOpenAttendanceModal(true);
																		}}
																		size="small"
																	>
																		<div className="action-content d-flex align-items-center">
																			<RefreshIcon />
																		</div>
																	</Button> */}
																<Button
																	variant={"outlined"}
																	onClick={() => {
																		setEntityToDelete({
																			type: "exercise",
																			id: te.id,
																		});
																		setOpenHandleConfirm(true);
																	}}
																	size="small"
																	color="error"
																>
																	<div className="action-content d-flex align-items-center">
																		<DeleteIcon />
																	</div>
																</Button>
															</div>
														</div>
														{/* </div> */}
													</div>
												);
											})}
											{!trainingExercises?.length && <EmptyData />}
										</div>
									</>
								}
							/>
						</div>
					</div>
				)}
				{hasRPE && (
					<div className="row no-margin">
						<div className="col-sm-12 padding-small mt-2">
							<VLFCard
								title={userRole === "Player" ? "" : "RPE"}
								content={
									<>
										{userRole !== "Player" && (
											<div className="d-flex align-items-center justify-content-end mb-2">
												<Button
													variant="contained"
													onClick={() => {
														setOpenRPEModal(true);
													}}
													size="small"
												>
													<div className="d-flex align-items-center">
														<div>Aggiungi</div>
													</div>
												</Button>
											</div>
										)}
										<div className="row col-container">
											{userRole !== "Player" ? (
												trainingData?.trainingRPE?.length ? (
													trainingData.trainingRPE.map((rpe, k) => {
														const currentPlayer = players.filter(
															(p) => p.id === rpe.playerId,
														)?.[0];
														return (
															<div
																key={k}
																className="col-sm-12 col-md-6 col-lg-4 mt-1 padding-small"
															>
																<div className="d-flex flex-column gap-2">
																	<div className="stats-player-container">
																		<div className="d-flex align-items-center gap-2">
																			<img
																				className="player-icon"
																				src={require("@/assets/images/coach/rpe.png")}
																				alt=""
																			/>
																			<div
																				className="player-name clickable"
																				onClick={() =>
																					navigate("/players/" + rpe.playerId)
																				}
																			>
																				{currentPlayer?.firstName +
																					" " +
																					currentPlayer?.lastName}
																			</div>
																		</div>
																		<div className="player-stat-container">
																			<div className="stat-value">
																				{
																					rpeOptions.filter(
																						(o) => o.value === rpe.rpe,
																					)?.[0]?.label
																				}
																			</div>
																		</div>
																		<div
																			className="stats-action clickable"
																			onClick={() => {
																				setEntityToDelete({
																					type: "rpe",
																					id: rpe.id,
																				});
																				setOpenHandleConfirm(true);
																			}}
																		>
																			<DeleteIcon
																				style={{
																					width: "18px",
																					height: "18px",
																				}}
																			/>
																		</div>
																	</div>
																</div>
															</div>
														);
													})
												) : (
													<div className="col-sm-12">
														<EmptyData />
													</div>
												)
											) : null}
											{userRole === "Player"
												? [
														<div
															key="convocation"
															className="col-sm-12 col-lg-6"
														>
															<div className="player-choice d-flex flex-column align-items-center">
																<div className="choice-label">
																	Hai partecipato all'allenamento?
																</div>
																<div className="choice-options">
																	{attendanceStatusOptions.map((as, k) => {
																		return (
																			<div
																				key={k}
																				className={
																					"single-option" +
																					(attendance === as.value
																						? " selected"
																						: "")
																				}
																				onClick={() => setAttendance(as.value)}
																			>
																				{as.label}
																			</div>
																		);
																	})}
																</div>
															</div>
														</div>,
														<div key="rpe" className="col-sm-12 col-lg-6">
															<div className="player-choice d-flex flex-column align-items-center">
																<div className="choice-label">RPE</div>
																<Rating
																	selectedRating={formData.rpe}
																	setSelectedRating={(value) => {
																		setFormData({...formData, rpe: value});
																	}}
																	max={10}
																	min={1}
																/>
																<div>
																	{
																		rpeOptions.filter(
																			(o) =>
																				o.value === formData.rpe?.toString(),
																		)?.[0]?.label
																	}
																</div>
															</div>
														</div>,
													]
												: null}
										</div>
									</>
								}
								openable={false}
							/>
						</div>
					</div>
				)}
				{userRole !== "Player" ? (
					<div className="row no-margin">
						<div className="col-sm-12 padding-small mt-2">
							<VLFCard
								title="Allegati"
								content={
									<AttachmentsContainer
										entityId={id}
										entityType="training"
										attachments={attachments}
										handleAdd={() => getTrainingAttachment()}
										handleDownload={getAttachment}
										handleDelete={(id) => {
											setEntityToDelete({type: "attachment", id});
											setOpenHandleConfirm(true);
										}}
										handleNotification={handleNotification}
									/>
								}
							/>
						</div>
					</div>
				) : null}
			</div>
			{openAttendanceModal ? (
				<HandleAttendanceModal
					open={openAttendanceModal}
					confirm={handleAttendance}
					close={handleClose}
					initialData={initialData}
				/>
			) : null}
			{openExerciseModal ? (
				<HandleExerciseModal
					open={openExerciseModal}
					confirm={handleExercise}
					close={handleClose}
					// initialData={initialData}
				/>
			) : null}
			{openRPEModal ? (
				<HandleRPEModal
					open={openRPEModal}
					confirm={handleMeasurement}
					close={handleClose}
					initialData={initialData}
				/>
			) : null}
			<VLFModal
				open={openHandleConfirm}
				title="Eliminazione"
				content={
					<div className="mt-2">
						Sei sicuro di voler eliminare questo elemento?
					</div>
				}
				confirm={() => {
					switch (entityToDelete?.type) {
						case "training":
							return deleteTraining();
						case "exercise":
							return deleteExercise();
						case "attachment":
							return deleteAttachment();
						case "attendance":
							return deleteAttendance();
						case "rpe":
							return deleteMeasurement();
						default:
							return;
					}
				}}
				close={() => setOpenHandleConfirm(false)}
				confirmLabel="Conferma"
			/>
		</Page>
	);
}

export default TrainingPage;
