import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import moment from "moment";

import AttachmentsContainer from "@/components/AttachmentsContainer";
import Loading from "@/components/Loading";
import Page from "@/components/Page";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";
import ViewField from "@/components/Input/ViewField";
import VLFModal from "@/components/Modal";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import {PlaylistAPI} from "@/api/playlist";
import {AttachmentAPI} from "@/api/attachment";

import {
	dateFormat,
	ERROR_OPERATION,
	SUCCESSFUL_OPERATION,
} from "@/utils/constants";
import {usePlayers} from "@/utils/hooks";

import "./index.scss";

function VideoPage(props) {
	const {handleNotification} = props;

	const type = new URLSearchParams(useLocation().search).get("type");

	const {id} = useParams();
	const navigate = useNavigate();

	const {players} = usePlayers({
		setLoading: false,
		handleNotification,
	});

	const [attachments, setAttachments] = useState([null]);
	const [loading, setLoading] = useState(false);
	const [openHandleConfirm, setOpenHandleConfirm] = useState(false);
	const [playlistData, setPlaylistData] = useState({});
	// const [idAttachment, setIdAttachment] = useState("");

	const getPlaylist = async () => {
		setLoading(true);
		try {
			const response = await PlaylistAPI.getById(id);
			response.player =
				players.filter((p) => p.id === response.playerId)?.[0] || {};
			setPlaylistData(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const getAttachments = async () => {
		setLoading(true);
		try {
			const response = await AttachmentAPI.getByTypeId("playlist", id);
			setAttachments(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (players?.length) {
			getPlaylist();
			getAttachments();
		}
	}, [players]);

	const downloadAttachment = async (idAttachment) => {
		setLoading(true);
		try {
			const currentFile = attachments.filter((a) => a.id === idAttachment)?.[0];

			const response = await AttachmentAPI.getById(idAttachment, {
				responseType: "blob",
			});

			const extension = currentFile.filePath
				.split("\\")
				.pop()
				.split(".")
				.pop()
				.toLowerCase();
			let mimeType = "video/mp4";

			if (extension === "mov") mimeType = "video/quicktime";

			const blob = new Blob([response], {type: mimeType});

			const url = window.URL.createObjectURL(blob);

			const a = document.createElement("a");
			a.href = url;

			a.download = currentFile.fileName || `video.${extension}`;
			document.body.appendChild(a);

			a.click();
			document.body.removeChild(a);
			window.URL.revokeObjectURL(url);

			handleNotification(SUCCESSFUL_OPERATION, "success");
		} catch (error) {
			console.error("Errore nel download del file", error);
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const deletePlaylist = async () => {
		setLoading(true);
		try {
			await PlaylistAPI.delete(id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			navigate("/video" + (type ? "?type=" + type : ""));
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		} finally {
			setOpenHandleConfirm(false);
		}
	};

	const mainConfiguration = [
		{
			value:
				playlistData?.creationDate &&
				moment(playlistData?.creationDate).format(dateFormat),
			label: "Data",
			size: "col-6 col-lg-3",
		},
		{value: playlistData?.name, label: "Nome", size: "col-6 col-lg-3"},
		{
			value: playlistData?.playlistType,
			label: "Tipologia",
			size: "col-6 col-lg-3",
		},
		{
			value: playlistData?.categories?.length ? (
				<div className="categories-list">
					{playlistData.categories.map((c, k) => {
						return (
							<div className="categories-element" key={k}>
								{c.playersCategory}
							</div>
						);
					})}
					{!playlistData?.categories?.length && <div>---</div>}
				</div>
			) : playlistData?.player ? (
				playlistData?.player?.firstName + " " + playlistData?.player?.lastName
			) : (
				"---"
			),
			label: playlistData?.categories?.length ? "Categorie" : "Portiere",
			size: "col-6 col-lg-3",
		},
	];
	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: () => navigate("/video" + (type ? "?type=" + type : "")),
			color: "info",
		},
		{
			icon: <EditIcon />,
			title: "Modifica",
			onClick: () =>
				navigate("/video/" + id + "/edit" + (type ? "?type=" + type : "")),
		},
		{
			icon: <DeleteIcon />,
			title: "Elimina",
			onClick: () => setOpenHandleConfirm(true),
			color: "error",
		},
	];

	const breadcrumbsElements = [
		{label: "Video", path: "/video" + (type ? "?type=" + type : "")},
		{label: "/"},
	];

	return (
		<Page className="player-container">
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				current={playlistData ? playlistData?.name || "" : ""}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="sheet-container">
				<div className="row no-margin col-container">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title={"Informazioni principali"}
							content={
								<div className="row">
									<div className="col-sm-12">
										<ViewField configuration={mainConfiguration} />
									</div>
								</div>
							}
							openable={false}
						/>
					</div>
					<div className="col-sm-12 padding-small mt-2">
						<VLFCard
							title="Multimedia"
							content={
								<div className="row">
									<div className="col-sm-12">
										<AttachmentsContainer
											entityId={id}
											entityType="playlist"
											attachments={attachments}
											handleDownload={downloadAttachment}
											// handleDelete={(AttachmentId) => {
											// 	setIdAttachment(AttachmentId);
											// 	setOpenHandleConfirm(true);
											// }}
											handleNotification={handleNotification}
											editable={false}
										/>
									</div>
								</div>
							}
							openable={false}
						/>
					</div>
				</div>
			</div>
			<VLFModal
				open={openHandleConfirm}
				title="Eliminazione"
				content={
					<div className="mt-2">
						Sei sicuro di voler eliminare questo elemento?
					</div>
				}
				confirm={deletePlaylist}
				close={() => setOpenHandleConfirm(false)}
				confirmLabel="Conferma"
			/>
		</Page>
	);
}

export default VideoPage;
