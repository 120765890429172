import VLFTextField from "@/components/Input/TextField";

import {REQUIRED_FIELD} from "@/utils/constants";

export const checkRequiredFields = (requiredFields, fields) => {
	const currentErrorsFields = [];
	requiredFields.forEach((rf) => {
		if (!fields[rf]) {
			currentErrorsFields.push(rf);
		}
	});

	return currentErrorsFields;
};

export const fetchData = async (
	func,
	filters,
	before,
	success,
	error,
	after,
) => {
	before && before();
	try {
		const response = await func(filters);
		success && success(response);
	} catch (e) {
		error && error();
	} finally {
		after && after();
	}
};

export const getTextField = ({
	fieldKey,
	type = "text",
	label,
	required = false,
	editable = true,
	disabled = false,
	values,
	setValues,
	errors,
	setErrors,
}) => {
	return (
		<VLFTextField
			value={values[fieldKey] || ""}
			onChange={(e) => {
				required && setErrors && setErrors([]);
				setValues({...values, [fieldKey]: e.target.value});
			}}
			type={type}
			label={label}
			placeholder={label}
			required={required}
			editable={editable}
			disabled={disabled}
			size="small"
			helperText={
				errors && errors?.indexOf(fieldKey) !== -1 ? REQUIRED_FIELD : ""
			}
			error={errors && errors?.indexOf(fieldKey) !== -1}
		/>
	);
};

export const getTextAreaField = ({
	fieldKey,
	label,
	required,
	minRows = 3,
	maxRows = 3,
	errors,
	setErrors,
	setValues,
	values,
}) => {
	return (
		<VLFTextField
			value={values[fieldKey] || ""}
			onChange={(e) => {
				required && setErrors && setErrors([]);
				setValues({...values, [fieldKey]: e.target.value});
			}}
			label={label}
			placeholder={label}
			required={required}
			helperText={errors.indexOf(fieldKey) !== -1 ? REQUIRED_FIELD : ""}
			error={errors.indexOf(fieldKey) !== -1}
			size="small"
			multiline
			minRows={minRows}
			maxRows={maxRows}
		/>
	);
};

export const getCurrentPlayer = (players, item, withCategory = false) => {
	const currentPlayer = players?.filter((p) => p.id === item.playerId)?.[0];
	return currentPlayer
		? currentPlayer?.firstName +
				" " +
				currentPlayer?.lastName +
				(withCategory ? " (" + currentPlayer?.category + ")" : "")
		: "";
};

export const getCurrentCategory = (categories, item) => {
	const currentCategory = categories?.filter(
		(c) => (c.value || c.id) === item.playerCategoryId,
	)?.[0];
	return currentCategory ? currentCategory?.name : "";
};

export const isCompetitiveActivity = (category) => {
	return parseInt(category?.split(" ")?.[1]) > 13;
};

const getHeightRange = (category) => {
	let minHeight,
		maxHeight = 0;

	switch (category) {
		case "19":
			minHeight = 188;
			maxHeight = 196;
			break;
		case "18":
			minHeight = 180;
			maxHeight = 196;
			break;
		case "17":
			minHeight = 180;
			maxHeight = 196;
			break;
		case "16":
			minHeight = 175;
			maxHeight = 190;
			break;
		case "15":
			minHeight = 170;
			maxHeight = 185;
			break;
		case "14":
			minHeight = 160;
			maxHeight = 180;
			break;
		case "13":
			minHeight = 160;
			maxHeight = 178;
			break;
		case "12":
			minHeight = 150;
			maxHeight = 170;
			break;
		case "11":
			minHeight = 145;
			maxHeight = 165;
			break;
		case "10":
			minHeight = 140;
			maxHeight = 160;
			break;
		case "09":
			minHeight = 130;
			maxHeight = 150;
			break;
		case "08":
			minHeight = 120;
			maxHeight = 140;
			break;
		default:
			break;
	}

	return {minHeight, maxHeight};
};
const getWeightRange = (category) => {
	let minWeight,
		maxWeight = 0;

	switch (category) {
		case "19":
			minWeight = 75;
			maxWeight = 85;
			break;
		case "18":
			minWeight = 72;
			maxWeight = 82;
			break;
		case "17":
			minWeight = 68;
			maxWeight = 78;
			break;
		case "16":
			minWeight = 62;
			maxWeight = 72;
			break;
		case "15":
			minWeight = 55;
			maxWeight = 65;
			break;
		case "14":
			minWeight = 46;
			maxWeight = 58;
			break;
		case "13":
			minWeight = 40;
			maxWeight = 50;
			break;
		case "12":
			minWeight = 32;
			maxWeight = 42;
			break;
		case "11":
			minWeight = 27;
			maxWeight = 37;
			break;
		case "10":
			minWeight = 25;
			maxWeight = 35;
			break;
		case "09":
			minWeight = 23;
			maxWeight = 33;
			break;
		case "08":
			minWeight = 20;
			maxWeight = 30;
			break;
		default:
			break;
	}

	return {minWeight, maxWeight};
};

export const getPlayerStructure = (categoria, altezza, peso) => {
	const {minHeight, maxHeight} = getHeightRange(categoria.toString());
	const {minWeight, maxWeight} = getWeightRange(categoria.toString());

	const altezzaNormalizzata = (altezza - minHeight) / (maxHeight - minHeight);
	const pesoNormalizzato = (peso - minWeight) / (maxWeight - minWeight);

	const strutturaNormalizzata =
		0.6 * altezzaNormalizzata + 0.4 * pesoNormalizzato;

	const strutturaFinale = Math.round(strutturaNormalizzata * 10);

	return Math.max(1, Math.min(10, strutturaFinale));
};
