import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import moment from "moment";

import Loading from "@/components/Loading";
import Page from "@/components/Page";
import Breadcrumbs from "@/components/Breadcrumbs";
import EmptyData from "@/components/EmptyData";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";
import ViewField from "@/components/Input/ViewField";
import Rating from "@/components/Rating";
import NotesSection from "@/components/NotesSection";
import AttachmentsContainer from "@/components/AttachmentsContainer";
import Button from "@mui/material/Button";
import VLFModal from "@/components/Modal";
import HandleStatModal from "@/pages/Manager/Match/handleStat";
import HandleAttendanceModal from "@/pages/Manager/Match/handleAttendance";
import HandleRPEModal from "@/pages/Manager/Match/handleRPE";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";
import SaveIcon from "@mui/icons-material/Save";

import {MatchAPI} from "@/api/match";
import {AttachmentAPI} from "@/api/attachment";

import {
	dateFormat,
	rpeOptions,
	attendanceStatusOptions,
	ERROR_OPERATION,
	SUCCESSFUL_OPERATION,
} from "@/utils/constants";
import {usePlayers} from "@/utils/hooks";

import "./index.scss";

function MatchPage(props) {
	const {handleNotification} = props;
	const {id} = useParams();
	const navigate = useNavigate();

	const basePath = "/matches";

	const userRole = useSelector((state) => state?.user?.roleName);
	const playerId = useSelector((state) => state?.user?.playerId);

	const [loading, setLoading] = useState(false);
	const [openHandleConfirm, setOpenHandleConfirm] = useState(false);
	const [matchData, setMatchData] = useState({});
	const [matchNotes, setMatchNotes] = useState([]);
	const [attachments, setAttachments] = useState([]);

	const [formData, setFormData] = useState({});

	const [openModal, setOpenModal] = useState(false);
	const [openAttendanceModal, setOpenAttendanceModal] = useState(false);
	const [openRPEModal, setOpenRPEModal] = useState(false);
	const [initialData, setInitialData] = useState();
	const [entityToDelete, setEntityToDelete] = useState({});

	const {players} = usePlayers({setLoading, handleNotification});
	const [attendance, setAttendance] = useState("");

	const getMatch = async () => {
		setLoading(true);
		try {
			const response = await MatchAPI.getById(id);
			setMatchData(response);

			if (userRole === "Player") {
				const playerMeasurement =
					response.matchRpe?.filter((ps) => ps.playerId === playerId)?.[0] ||
					{};
				setFormData({
					...Object.assign({}, playerMeasurement),
					rpe: parseInt(playerMeasurement.rpe),
				});

				const playerConvocation = response.matchConvocations?.filter(
					(c) => c.playerId === playerId,
				)?.[0];

				setAttendance(playerConvocation ? playerConvocation.status : undefined);
				setLoading(false);
			}
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const getMatchNotes = async () => {
		try {
			const response = await MatchAPI.getNotes(id);
			setMatchNotes(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};

	const getMatchAttachment = async () => {
		try {
			const response = await AttachmentAPI.list("match", id);
			setAttachments(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};

	useEffect(() => {
		getMatch();
		getMatchAttachment();
		getMatchNotes();
	}, []);

	const deleteMatch = async () => {
		setLoading(true);
		try {
			await MatchAPI.delete(matchData?.id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			navigate(basePath);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		}
	};
	const deleteAttachment = async () => {
		setLoading(true);
		try {
			await AttachmentAPI.delete(entityToDelete?.id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			setOpenHandleConfirm(false);
			getMatchAttachment();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const deleteEvaluation = async () => {
		setLoading(true);
		try {
			await MatchAPI.deleteEvaluation(matchData?.id, entityToDelete?.id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			setOpenHandleConfirm(false);
			getMatch();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const deleteAttendance = async () => {
		setLoading(true);
		try {
			await MatchAPI.deleteAttendance(matchData?.id, entityToDelete?.id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			setOpenHandleConfirm(false);
			getMatch();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const deleteMeasurement = async () => {
		setLoading(true);
		try {
			await MatchAPI.deleteMeasurement(matchData?.id, entityToDelete?.id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			setOpenHandleConfirm(false);
			getMatch();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const getAttachment = async (id) => {
		setLoading(true);
		try {
			const currentFile = attachments.filter((a) => a.id === id)?.[0];
			const response = await AttachmentAPI.getById(id, {responseType: "blob"});

			// Ottieni l'estensione del file
			const extension = currentFile.filePath
				.split("\\")
				.pop()
				.split(".")
				.pop()
				.toLowerCase();
			let mimeType = "application/octet-stream"; // Default per file sconosciuti

			// eslint-disable-next-line default-case
			switch (extension) {
				case "pdf":
					mimeType = "application/pdf";
					break;
				case "png":
					mimeType = "image/png";
					break;
				case "jpg":
				case "jpeg":
					mimeType = "image/jpeg";
					break;
				case "doc":
					mimeType = "doc";
					break;
			}

			const blob = new Blob([response], {type: mimeType});

			const url = window.URL.createObjectURL(blob);

			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", currentFile.filePath.split("\\").pop());

			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			handleNotification(SUCCESSFUL_OPERATION, "success");
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const handleClose = () => {
		setOpenModal(false);
		setOpenRPEModal(false);
		setOpenAttendanceModal(false);
		setInitialData();
	};

	const handleStat = async (data, id) => {
		setLoading(true);
		try {
			const body = {
				matchId: matchData?.id,
				...data,
				playerId: data.player?.value,
			};

			delete body.player;

			id
				? await MatchAPI.updateEvaluation(matchData?.id, id, body)
				: await MatchAPI.createEvaluation(body);
			getMatch();
			handleClose();
			handleNotification(SUCCESSFUL_OPERATION, "success");
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const handleAttendance = async (data, id) => {
		setLoading(true);
		try {
			const body = playerId
				? data
				: {
						matchId: matchData?.id,
						...data,
						playerId: data.player?.value,
						status: data.status?.value,
					};

			delete body.player;

			id
				? await MatchAPI.updateAttendance(matchData?.id, id, body)
				: await MatchAPI.createAttendance(body);
			getMatch();
			handleClose();
			handleNotification(SUCCESSFUL_OPERATION, "success");
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const handleMeasurement = async (data, id) => {
		setLoading(true);
		try {
			const rpe =
				userRole === "Player"
					? data.rpe || undefined
					: data.rpe.value || undefined;

			if (rpe) {
				const body = {
					matchId: matchData?.id,
					playerId: userRole === "Player" ? playerId : data.player.value,
					...data,
					rpe,
				};
				id
					? await MatchAPI.updateMeasurement(matchData?.id, id, body)
					: await MatchAPI.createMeasurement(body);
			}

			if (userRole === "Player") {
				const playerConvocation = matchData.matchConvocations?.filter(
					(c) => c.playerId === playerId,
				)?.[0];

				await handleAttendance(
					{
						id: playerConvocation?.id,
						matchId: matchData?.id,
						playerId,
						status: rpe ? attendanceStatusOptions[0].label : attendance,
					},
					playerConvocation?.id,
				);
				navigate(basePath);
			} else {
				getMatch();
				handleClose();
			}
			handleNotification(SUCCESSFUL_OPERATION, "success");
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const handleNote = async (data, id) => {
		setLoading(true);
		try {
			const body = {
				date: data?.date || moment().format("YYYY-MM-DD"),
				matchId: matchData?.id,
				...data,
			};
			delete body.player;

			id
				? await MatchAPI.updateNote(matchData?.id, id, body)
				: await MatchAPI.createNote(body);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			getMatchNotes();
			handleClose();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};
	const deleteNote = async (id) => {
		setLoading(true);
		try {
			await MatchAPI.deleteNote(matchData?.id, id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			getMatchNotes();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	const matchConfiguration = [
		{
			value: matchData?.playersCategory,
			label: "Categoria",
			size: "col-6 col-lg-4",
		},
		{
			value: matchData?.startDate
				? moment(matchData.startDate).format(dateFormat + " HH:mm")
				: null,
			label: "Data",
			size: "col-6 col-lg-4",
		},
		{
			value: matchData?.startDate
				? moment
						.duration(
							moment(matchData?.endDate).diff(moment(matchData?.startDate)),
						)
						.asMinutes() + "'"
				: null,
			label: "Durata",
			size: "col-6 col-lg-4",
		},
		{
			value: matchData?.homeTeam,
			label: "Squadra casa",
			size: "col-6 col-lg-4",
		},
		{
			value: matchData?.awayTeam,
			label: "Squadra ospite",
			size: "col-6 col-lg-4",
		},
		{
			value: matchData?.results,
			label: "Risultato",
			size: "col-6 col-lg-4",
		},
		{
			value: matchData?.matchType,
			label: "Tipologia",
			size: "col-6 col-lg-4",
		},
	];

	matchConfiguration.push(
		{
			value: matchData?.fieldName,
			label: "Stadio",
			size: "col-6 col-lg-4",
		},
		{
			value: matchData?.description,
			label: "Descrizione",
			size: "col-6 col-lg-4",
		},
	);

	const getAttendanceStatusIcon = (status) => {
		return status === "Presente" ? "on" : "off";
	};

	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: () => navigate(basePath),
			color: "info",
		},
	];

	if (userRole === "Player") {
		mainActions.push({
			icon: <SaveIcon />,
			title: "Salva",
			onClick: () => handleMeasurement(formData, formData?.id),
			disabled: !formData.rpe && !attendance,
		});
	} else {
		if (userRole !== "Analyst") {
			mainActions.push(
				{
					icon: <EditIcon />,
					title: "Modifica",
					onClick: () => navigate(basePath + "/" + matchData?.id + "/edit"),
				},
				{
					icon: <DeleteIcon />,
					title: "Elimina",
					onClick: () => {
						setEntityToDelete({type: "match", id});
						setOpenHandleConfirm(true);
					},
					color: "error",
				},
			);
		}
	}

	const breadcrumbsElements = [
		{label: "Partite", path: basePath},
		{label: "/"},
	];

	const hasRPE = parseInt(matchData?.playersCategory?.split(" ")?.[1]) >= 13;

	return (
		<Page>
			<Loading visible={loading} />
			<div className="player-container">
				<Breadcrumbs
					elements={breadcrumbsElements}
					current={
						matchData?.startDate
							? matchData.homeTeam + " - " + matchData.awayTeam
							: ""
					}
					actions={<MainActions actions={mainActions} />}
				/>
				<div className="sheet-container">
					<div className="row no-margin col-container">
						<div className="col-sm-12 padding-small">
							<VLFCard
								title="Informazioni principali"
								content={
									<div className="row">
										<div className="col-sm-12 col-md-2">
											<div className="image-container generic">
												<img
													src={require("@/assets/images/coach/match.png")}
													alt=""
												/>
											</div>
										</div>
										<div className="col-sm-12 col-md-10">
											<ViewField configuration={matchConfiguration} />
										</div>
									</div>
								}
							/>
						</div>
						{userRole !== "Analyst" && userRole !== "Player" && (
							<div className="col-sm-12 padding-small mt-2">
								<VLFCard
									title="Convocazioni"
									content={
										<>
											<div className="d-flex align-items-center justify-content-end mb-2">
												<Button
													variant="contained"
													onClick={() => {
														setOpenAttendanceModal(true);
													}}
													size="small"
												>
													<div className="d-flex align-items-center">
														<div>Aggiungi</div>
													</div>
												</Button>
											</div>
											<div className="row">
												{matchData?.matchConvocations?.map((mc, k) => {
													const currentPlayer = players.filter(
														(p) => p.id === mc.playerId,
													)?.[0];
													return (
														<div
															key={k}
															className="col-12 col-xl-6 padding-small"
														>
															<div className="d-flex flex-column gap-2">
																<div className="stats-player-container attendance mt-1">
																	<div className="d-flex align-items-center gap-2">
																		<img
																			className="player-icon"
																			src={require("@/assets/images/manager/player.png")}
																			alt=""
																		/>
																		<div
																			className="player-name clickable match"
																			onClick={() =>
																				navigate("/players/" + mc.playerId)
																			}
																		>
																			{currentPlayer?.firstName +
																				" " +
																				currentPlayer?.lastName}
																		</div>
																	</div>
																	<div className="stats-container d-flex align-items-center">
																		<div className="player-stat-container flex-row gap-2">
																			<div className="stat-label">
																				{mc.status || "---"}
																			</div>
																			<div
																				className={
																					"stat-label-icon " +
																					getAttendanceStatusIcon(mc.status)
																				}
																			/>
																		</div>
																	</div>
																	<div className="d-flex align-items-center gap-2">
																		<Button
																			variant={"outlined"}
																			onClick={() => {
																				setInitialData({
																					id: mc.id,
																					playerId: mc.playerId,
																					status: mc.status,
																				});
																				setOpenAttendanceModal(true);
																			}}
																			size="small"
																		>
																			<div className="action-content d-flex align-items-center">
																				<RefreshIcon />
																			</div>
																		</Button>
																		<Button
																			variant={"outlined"}
																			onClick={() => {
																				setEntityToDelete({
																					type: "attendance",
																					id: mc.id,
																				});
																				setOpenHandleConfirm(true);
																			}}
																			size="small"
																			color="error"
																		>
																			<div className="action-content d-flex align-items-center">
																				<DeleteIcon />
																			</div>
																		</Button>
																	</div>
																</div>
															</div>
														</div>
													);
												})}
												{!matchData?.matchConvocations?.length && <EmptyData />}
											</div>
										</>
									}
								/>
							</div>
						)}
						{userRole !== "Analyst" && userRole !== "Player" && (
							<div className="col-sm-12 padding-small mt-2">
								<VLFCard
									title="Minutaggi"
									content={
										<>
											<div className="d-flex align-items-center justify-content-end mb-2">
												<Button
													variant="contained"
													onClick={() => {
														setInitialData({
															...initialData,
															attendances: matchData?.matchConvocations,
														});
														setOpenModal(true);
													}}
													size="small"
												>
													<div className="d-flex align-items-center">
														<div>Aggiungi</div>
													</div>
												</Button>
											</div>
											<div className="row">
												<div className="col-sm-12">
													<div className="d-flex flex-column gap-2">
														{matchData?.matchEvaluations?.map((me, k) => {
															const currentPlayer = players.filter(
																(p) => p.id === me.playerId,
															)?.[0];
															return (
																<div
																	key={k}
																	className="stats-player-container mt-1"
																>
																	<div className="d-flex align-items-center gap-2">
																		<img
																			className="player-icon"
																			src={require("@/assets/images/coach/analysis.png")}
																			alt=""
																		/>
																		<div
																			className="player-name clickable match"
																			onClick={() =>
																				navigate("/players/" + me.playerId)
																			}
																		>
																			{currentPlayer?.firstName +
																				" " +
																				currentPlayer?.lastName}
																		</div>
																	</div>
																	<div className="stats-container d-flex align-items-center my-1">
																		<div className="player-stat-container">
																			<div className="stat-value">
																				{me?.minutes !== null &&
																				me?.minutes !== undefined
																					? me?.minutes
																					: "---"}
																			</div>
																			<div className="stat-label">Minuti</div>
																		</div>
																		<div className="player-stat-container">
																			<div className="stat-value">
																				{me?.goalsConceded !== null &&
																				me?.goalsConceded !== undefined
																					? me?.goalsConceded
																					: "---"}
																			</div>
																			<div className="stat-label">
																				Gol subiti
																			</div>
																		</div>
																	</div>
																	<div className="d-flex align-items-center gap-2">
																		<Button
																			variant={"outlined"}
																			onClick={() => {
																				setInitialData({
																					id: me.id,
																					playerId: me.playerId,
																					minutes: me.minutes,
																					goalsConceded: me.goalsConceded,
																					attendances:
																						matchData?.matchConvocations,
																				});
																				setOpenModal(true);
																			}}
																			size="small"
																		>
																			<div className="action-content d-flex align-items-center">
																				<RefreshIcon />
																			</div>
																		</Button>
																		<Button
																			color="error"
																			variant={"outlined"}
																			onClick={() => {
																				setEntityToDelete({
																					type: "evaluation",
																					id: me.id,
																				});
																				setOpenHandleConfirm(true);
																			}}
																			size="small"
																		>
																			<div className="action-content d-flex align-items-center">
																				<DeleteIcon />
																			</div>
																		</Button>
																	</div>
																</div>
															);
														})}
														{!matchData?.matchEvaluations?.length && (
															<EmptyData />
														)}
													</div>
												</div>
											</div>
										</>
									}
								/>
							</div>
						)}
						{userRole !== "Analyst" && hasRPE && (
							<div className="col-sm-12 padding-small mt-2">
								<VLFCard
									title={userRole === "Player" ? "" : "RPE"}
									content={
										<>
											{userRole !== "Player" && (
												<div className="d-flex align-items-center justify-content-end mb-2">
													<Button
														variant="contained"
														onClick={() => {
															setOpenRPEModal(true);
														}}
														size="small"
													>
														<div className="d-flex align-items-center">
															<div>Aggiungi</div>
														</div>
													</Button>
												</div>
											)}
											<div className="row">
												{userRole !== "Player" ? (
													matchData?.matchRpe?.length ? (
														matchData.matchRpe.map((rpe, k) => {
															const currentPlayer = players.filter(
																(p) => p.id === rpe.playerId,
															)?.[0];
															return (
																<div
																	key={k}
																	className="col-sm-12 col-md-6 padding-small"
																>
																	<div className="d-flex flex-column gap-2">
																		<div className="stats-player-container rpe mt-1">
																			<div className="d-flex align-items-center gap-2">
																				<img
																					className="player-icon"
																					src={require("@/assets/images/coach/rpe.png")}
																					alt=""
																				/>
																				<div
																					className="player-name clickable"
																					onClick={() =>
																						navigate("/players/" + rpe.playerId)
																					}
																				>
																					{currentPlayer?.firstName +
																						" " +
																						currentPlayer?.lastName}
																				</div>
																			</div>
																			<div className="player-stat-container">
																				<div className="stat-value">
																					{
																						rpeOptions.filter(
																							(o) => o.value === rpe.rpe,
																						)?.[0]?.label
																					}
																				</div>
																			</div>
																			<div
																				className="stats-action clickable"
																				onClick={() => {
																					setEntityToDelete({
																						type: "rpe",
																						id: rpe.id,
																					});
																					setOpenHandleConfirm(true);
																				}}
																			>
																				<DeleteIcon
																					style={{
																						width: "18px",
																						height: "18px",
																					}}
																				/>
																			</div>
																		</div>
																	</div>
																</div>
															);
														})
													) : (
														<div className="col-sm-12">
															<EmptyData />
														</div>
													)
												) : null}
												{userRole === "Player"
													? [
															<div
																key="convocation"
																className="col-sm-12 col-lg-6"
															>
																<div className="player-choice d-flex flex-column align-items-center">
																	<div className="choice-label">
																		Hai partecipato alla partita?
																	</div>
																	<div className="choice-options">
																		{attendanceStatusOptions.map((as, k) => {
																			return (
																				<div
																					key={k}
																					className={
																						"single-option" +
																						(attendance === as.value
																							? " selected"
																							: "")
																					}
																					onClick={() =>
																						setAttendance(as.value)
																					}
																				>
																					{as.label}
																				</div>
																			);
																		})}
																	</div>
																</div>
															</div>,
															<div key="rpe" className="col-sm-12 col-lg-6">
																<div className="player-choice d-flex flex-column align-items-center">
																	<div className="choice-label">RPE</div>
																	<Rating
																		selectedRating={formData.rpe}
																		setSelectedRating={(value) => {
																			setFormData({...formData, rpe: value});
																		}}
																		max={10}
																		min={1}
																	/>
																	<div>
																		{
																			rpeOptions.filter(
																				(o) =>
																					o.value === formData.rpe?.toString(),
																			)?.[0]?.label
																		}
																	</div>
																</div>
															</div>,
														]
													: null}
											</div>
										</>
									}
									openable={false}
								/>
							</div>
						)}
						<div className="col-sm-12 no-padding mt-2">
							<NotesSection
								type="match"
								notes={matchNotes}
								handleConfirm={handleNote}
								handleDelete={deleteNote}
							/>
						</div>
						{userRole !== "Player" && (
							<div className="col-sm-12 padding-small mt-2">
								<VLFCard
									title="Allegati"
									content={
										<AttachmentsContainer
											entityId={id}
											entityType="match"
											attachments={attachments}
											handleAdd={() => getMatchAttachment()}
											handleDownload={getAttachment}
											handleDelete={(id) => {
												setEntityToDelete({type: "attachment", id});
												setOpenHandleConfirm(true);
											}}
											handleNotification={handleNotification}
										/>
									}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
			<VLFModal
				open={openHandleConfirm}
				title="Eliminazione"
				content={
					<div className="mt-2">
						Sei sicuro di voler eliminare questo elemento?
					</div>
				}
				confirm={() => {
					switch (entityToDelete?.type) {
						case "match":
							return deleteMatch();
						case "attachment":
							return deleteAttachment();
						case "evaluation":
							return deleteEvaluation();
						case "attendance":
							return deleteAttendance();
						case "rpe":
							return deleteMeasurement();
						default:
							return;
					}
				}}
				close={() => setOpenHandleConfirm(false)}
				confirmLabel="Conferma"
			/>
			{openModal ? (
				<HandleStatModal
					open={openModal}
					confirm={handleStat}
					close={handleClose}
					initialData={initialData}
				/>
			) : null}
			{openRPEModal ? (
				<HandleRPEModal
					open={openRPEModal}
					confirm={handleMeasurement}
					close={handleClose}
					initialData={initialData}
				/>
			) : null}
			{openAttendanceModal ? (
				<HandleAttendanceModal
					open={openAttendanceModal}
					confirm={handleAttendance}
					close={handleClose}
					initialData={initialData}
				/>
			) : null}
		</Page>
	);
}

export default MatchPage;
