import React from "react";

import CreatableSelect from "react-select/creatable";
import Select from "react-select";

const VLFSelectNew = ({
	options,
	value,
	onChange,
	label,
	placeholder = "Seleziona...",
	isMulti = false,
	isClearable = true,
	isDisabled = false,
	required = false,
	editable = true,
	sorted = true,
	sortOrder = "ascend",
	error = false,
	onCreateOption,
	styles = {},
	controlStyles = {},
	theme = {},
	...props
}) => {
	const Component = onCreateOption ? CreatableSelect : Select;
	const sortedOptions = sorted
		? options
				?.slice()
				.sort((a, b) =>
					sortOrder === "ascend"
						? a.label?.localeCompare(b.label)
						: b.label?.localeCompare(a.label),
				)
		: options;

	return (
		<>
			<div className="field-label-container">
				<div className="field-label">{label}</div>
				{required && editable && <div className="field-required">*</div>}
			</div>
			{editable ? (
				<>
					<Component
						options={sortedOptions}
						value={value}
						onChange={onChange}
						onCreateOption={onCreateOption}
						placeholder={placeholder}
						isMulti={isMulti}
						isClearable={isClearable}
						isDisabled={isDisabled}
						menuPortalTarget={document.body}
						styles={{
							control: (provided) => ({
								...provided,
								marginTop: "0px",
								marginBottom: "8px",
								...controlStyles,
								height: !isMulti ? "40px" : "100%",
								fontSize: "16px",
								borderColor: error ? "#e74c3c" : "#ccc",
							}),
							option: (styles, {data}) => {
								return {
									...styles,
									color: data.color || undefined,
								};
							},
							menu: (provided) => ({
								...provided,
								zIndex: 9999,
							}),
							menuPortal: (provided) => ({
								...provided,
								zIndex: 9999,
							}),
							...styles,
						}}
						menuPlacement={"auto"}
						theme={(defaultTheme) => ({
							...defaultTheme,
							...theme,
						})}
						{...props}
					/>
					{error && (
						<p
							className="MuiFormHelperText-root Mui-error"
							style={{color: "#d32f2f"}}
						>
							Campo obbligatorio
						</p>
					)}
				</>
			) : (
				<div className="field-value">{value}</div>
			)}
		</>
	);
};

export default VLFSelectNew;
